import Iframe from "./Iframe"
import React from "react"

// component contaning an iframe using the endpoint

function Embed(props: any) {

  return (
    <div id={`embed-${props.i}`} {...(props.i != 0 && { style: { display: "none" } })}>
        <Iframe name={props.name} i={props.i}/>
    </div>
  );
}
export default Embed;
