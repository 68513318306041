import * as yup from "yup";
import { useFormik } from "formik";
import { PopulationSourcePicker, PopulationType } from "./Population";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import {SqlFilter} from "../utils/reusableComponents"

const wamPopulationValidationSchema = yup.object({
  id: yup
    .string()
    .required("An id is required for the audience")
    .min(1, "An id is required for the audience")
    .matches(/^[a-zA-Z0-9]*$/, "ID can only contains letters and numbers"),
});

function WAMPopulation(props: any) {
  const formik = useFormik({
    initialValues: {
      id: "",
      sql_condition: "",
      id_type: "custom_segments",
    },
    validationSchema: wamPopulationValidationSchema,
    onSubmit: (value: any) => {
      value.pop_type = "WAM";
      props.after(value);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={4} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <PopulationSourcePicker
            handler={props.sourceHandler}
            value={PopulationType.WAM}
            availableData={props.selectedClient.available_data}
            countryPopulation={props.selectedProject.country}
            {...props}
          />
        </Grid>

        <Grid item xs={12}>
          <Tooltip title="Type of WAM audience">
            <TextField
              id="id_type"
              select
              label="ID Type"
              value={formik.values.id_type}
              onChange={formik.handleChange("id_type")}
              helperText="Select an id Type"
              fullWidth
            >
              <MenuItem value={"custom_segments"}>Custom Segment</MenuItem>
              <MenuItem value={"audiences"}>Audience</MenuItem>
            </TextField>
          </Tooltip>
        </Grid>

        <Grid item xs={12}>
          <Tooltip title="ID of your audience, ex : 223452">
            <TextField
              id="id"
              name="id"
              label="ID"
              value={formik.values.id}
              onChange={formik.handleChange("id")}
              error={formik.touched.id && Boolean(formik.errors.id)}
              helperText={formik.touched.id && formik.errors.id}
              fullWidth
            />
          </Tooltip>
        </Grid>

        <Grid item xs={12}>
          <SqlFilter
            title='More specific filter for your audience (ex: techno like "%Win10%") or intersection with one or more WAM audiences(ex: audiences like "%123456%")'
            id="sql_condition"
            name="sql_condition"
            label="SQL Filter (optional)"
            value={formik.values.sql_condition}
            onChange={formik.handleChange("sql_condition")}
            error={
              formik.touched.sql_condition &&
              Boolean(formik.errors.sql_condition)
            }
            helperText={
              formik.touched.sql_condition && formik.errors.sql_condition
            }
          ></SqlFilter>

        </Grid>

        <Grid item xs={10}>
          <Button
            type="submit"
            variant="contained"
            endIcon={<SendIcon />}
            fullWidth
          >
            Next
          </Button>
          <br />
          <br />
        </Grid>

        <Grid item xs={10}>
          <Button variant="contained" fullWidth onClick={props.prevStep}>
            Back
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default WAMPopulation;
