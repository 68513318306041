import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useFormik } from "formik";
import * as yup from "yup";
import { PopulationSourcePicker, PopulationType } from "./Population";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import { sqlQueryValidator } from "../validators";
import Tooltip from "@mui/material/Tooltip";

const customQueryPopulationValidationSchema = yup.object({
  sql_condition: sqlQueryValidator,
});

function CustomQueryPopulation(props: any) {
  const formik = useFormik({
    initialValues: {
      sql_condition: "",
    },
    validationSchema: customQueryPopulationValidationSchema,
    onSubmit: (value: any) => {
      value.pop_type = "CQ";
      props.after(value);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={4} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <PopulationSourcePicker
            handler={props.sourceHandler}
            value={PopulationType.CQ}
            availableData={props.selectedClient.available_data}
            countryPopulation={props.selectedProject.country}
            {...props}
          />
        </Grid>

        <Grid item xs={12}>
          <Tooltip
            title='Full custom population, we need at first 3 columns : aud_weboid, timehit, audience_name
		ex : weboid as aud_weboid, UNIX_MILLIS(TIMESTAMP "2008-12-25 15:30:00+00") as timehit, "POSITIVE_POP" as audience_name
		weboid as aud_weboid, UNIX_MILLIS(timestamp) as timehit, "NEGATIVE_POP" as audience_name'
          >
            <TextField
              id="sql_condition"
              name="sql_condition"
              label="Custom query"
              value={formik.values.sql_condition}
              onChange={formik.handleChange("sql_condition")}
              error={
                formik.touched.sql_condition &&
                Boolean(formik.errors.sql_condition)
              }
              helperText={
                formik.touched.sql_condition && formik.errors.sql_condition
              }
              fullWidth
              multiline={true}
              rows={12}
            />
          </Tooltip>
        </Grid>

        <Grid item xs={10}>
          <Button
            type="submit"
            variant="contained"
            endIcon={<SendIcon />}
            fullWidth
          >
            Next
          </Button>
          <br />
          <br />
        </Grid>

        <Grid item xs={10}>
          <Button variant="contained" fullWidth onClick={props.prevStep}>
            Back
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
export default CustomQueryPopulation;
