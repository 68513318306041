import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import { useState } from "react";
import * as yup from "yup";
import { SelectWcmID, SelectWamID } from "./reusableComponents";
import { useAuth } from "../../contexts/AuthContext";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { SelectCountry } from "../utils/reusableComponents";
import {onlyNumber} from "../validators"
import { v4 as uuidv4 } from "uuid";
import {now} from '../utils/utils';

const newClientValidationSchema = yup.object({
  client_name: yup
    .string()
    .required("A name is required for the client")
    .min(1, "A name is required for the client")
    .matches(
      /^[a-zA-Z0-9_ ]*$/,
      "Client name can only contain letters, numbers, underscores or spaces"
    ),
  country: yup
  .string()
  .required("Country is required")
});

function NewClient() {
  const { currentUser } = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  async function sendCreateClientRequest(
    createClientRequest: any,
    callback: any
  ) {
    currentUser.getIdToken(true).then(function (idToken: any) {
      fetch("/datastore/create/client", {
        method: "post",
        headers: { Authorization: idToken, "Content-Type": "application/json" },
        body: JSON.stringify(createClientRequest),
      })
        .then((response) => response.json())
        .then((responseData) => {
          callback(responseData);
        });
    });
  }

  function afterCall(response: any) {
    enqueueSnackbar(response.message);
    if (response.result != "error") {
      navigate("/home");
    } else {
      setIsSubmitting(false);
    }
  }

  const formik = useFormik({
    initialValues: {
      client_name: "",
      country: "",
      wam_id: "",
      wcm_id: "",
    },
    validationSchema: newClientValidationSchema,
    onSubmit: (value: any) => {
      setIsSubmitting(true);
      enqueueSnackbar(`Creating client ${value.client_name}...`);
      value.metadata = { user: {uid: currentUser.uid, email:currentUser.email}, creation_date:now()}
      sendCreateClientRequest(value, afterCall);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={4} alignItems="center" justifyContent="center">
        <Grid item xs={10}>
          <TextField
            id="client_name"
            name="client_name"
            label="Client name"
            value={formik.values.client_name}
            onChange={formik.handleChange("client_name")}
            error={formik.touched.client_name && Boolean(formik.errors.client_name)}
            helperText={formik.touched.client_name && formik.errors.client_name}
            fullWidth
          />
        </Grid>

        <Grid item xs={10}>
          <SelectCountry
            value={formik.values.country}
            onChange={formik.handleChange("country")}
            error={formik.touched.country && Boolean(formik.errors.country)}
            helperText={formik.touched.country && formik.errors.country}
          />
        </Grid>

        <Grid item xs={10}>
          <SelectWcmID
            onChange={(event: any, value: any): void => {
              formik.setFieldValue("wcm_id", value);
            }}
            multiple={false}
            error={formik.touched.wcm_id && Boolean(formik.errors.wcm_id)}
            helperText={formik.touched.wcm_id && formik.errors.wcm_id}
          />
        </Grid>

        <Grid item xs={10}>
          <SelectWamID
            onChange={(event: any, value: any): void => {
              formik.setFieldValue("wam_id", value);
            }}
            multiple={false}
            error={formik.touched.wam_id && Boolean(formik.errors.wam_id)}
            helperText={formik.touched.wam_id && formik.errors.wam_id}
          />
        </Grid>

{/* 
        <Grid item xs={10}>
          <TextField
            id="wam_id"
            name="wam_id"
            label="WAM ID"
            value={formik.values.wam_id}
            onChange={formik.handleChange("wam_id")}
            error={formik.touched.wam_id && Boolean(formik.errors.wam_id)}
            helperText={formik.touched.wam_id && formik.errors.wam_id}
            fullWidth
          />
        </Grid> */}

        <Grid item xs={10}>
          <Button
            type="submit"
            variant="contained"
            endIcon={<SendIcon />}
            disabled={isSubmitting}
            fullWidth
          >
            Create new client
          </Button>
          <br />
          <br />
        </Grid>


      </Grid>
    </form>
  );
}

export default NewClient;
