import React from "react";
import Grid from "@mui/material/Grid";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import {
  SelectClient,
  SelectCountry,
  SelectProject,
  CreateProject,
} from "../utils/reusableComponents";
import * as yup from "yup";
import { scoreNameValidator, projectNameValidator } from "../validators";
import IconButton from "@mui/material/IconButton";
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Bilan from "./Bilan";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { CSSProperties } from 'react';

function ReportSelection(props: any) {
  // TODO: client_name validation
  const [bilanType, setBilanType] = React.useState('REGION');

  const handleChange = (event: SelectChangeEvent) => {
    setBilanType(event.target.value);
  };

  const navigate = useNavigate();

  const centeredMessageStyle: CSSProperties = { textAlign: 'center',
  color: 'red',
  border: '2px solid red',
  padding: '10px' };

  const validationSchema = yup.object({
    country: yup.string().required("A country is required"),
    score_name: scoreNameValidator,
    project_name: projectNameValidator
  });
  

  return (
    <div>
        {<Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          direction="row"
        >
          <Grid item xs={10}>
              <Bilan
                    type={handleChange}
                    createRequest={props.createRequest}
                  />
          </Grid>
        </Grid> }

    </div>
  );
}

export default ReportSelection;
