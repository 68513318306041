import React from "react";
import { v4 as uuidv4 } from "uuid";
import { useState, useEffect } from "react";
import ScoreClientSelection from "../score/ScoreClientSelection";
import ReportSelection from "./ReportSelection";
import ScorePopulation from "../score/ScorePopulation";
import SegmentSelection from "../score/SegmentSelection";
import { useAuth } from '../../contexts/AuthContext'
import { useNavigate } from "react-router-dom";
import usePostRequest from "../utils/requests"
import { useSnackbar } from "notistack";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import {now} from '../utils/utils';

export enum ScoreStep {
  CLIENT_SELECTION = "CLIENT_SELECTION",
  SCORE_POPULATION = "SCORE_POPULATION",
  SEGMENT_SELECTION = "SEGMENT_SELECTION",
}

const steps = ["Setup"];

function Report(props: any) {
  const [step, setStep] = useState(ScoreStep.CLIENT_SELECTION);
  const [activeStep, setActiveStep] = useState(0);

  const { currentUser } = useAuth();
  const runPostRequest = usePostRequest()
  
  const createRequest = async (reportValues: any) => {
    // setSelectedSegments(selectedSegments)
    // need_df is always true for now
    const date_start = new Date(reportValues.date_start.getTime() - reportValues.date_start.getTimezoneOffset() * 60000).toISOString()
    const date_end = new Date(reportValues.date_end.getTime() - reportValues.date_end.getTimezoneOffset() * 60000).toISOString()

    const uuid = uuidv4()
    const metadata = {'uuid':uuid, state: "REGION_REPORT_NEW_REQUEST",creation_date:now(), user: {uid: currentUser.uid, email:currentUser.email}}
    const reportRequest = {'UUID': uuid, 'Report_Name': reportValues.report_name, 'Report Information': {adv_id : reportValues.advertiser_id, io_id : reportValues.io_id, date_start: date_start, date_end: date_end, budget: reportValues.budget, report_type: reportValues.report_type}, 'metadata':metadata}
    const messages = {"success": `Report '${reportValues.report_name}' succesfully created`, "error":`An error occured while creating score '${reportValues.report_name}'`}
    await runPostRequest(reportRequest, "/datastore/create/activation_report", "/history", false, messages)
  }

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={10}>
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <div>
          <br></br>
          <ReportSelection
            createRequest={createRequest}
          />
        </div>
      </Grid>
    </Grid>
  );
}

export default Report;
