import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useFormik } from "formik";
import * as yup from "yup";
import { PopulationSourcePicker, PopulationType } from "./Population";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

const wfPopulationValidationSchema = yup.object({});

function WFPopulation(props: any) {
  const formik = useFormik({
    initialValues: {
      sql_condition: "",
    },
    validationSchema: wfPopulationValidationSchema,
    onSubmit: (value: any) => {
      value.pop_type = "WF";
      props.after(value);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={4} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <PopulationSourcePicker
            handler={props.sourceHandler}
            value={PopulationType.WF}
            availableData={props.selectedClient.available_data}
            countryPopulation={props.selectedProject.country}
            {...props}
          />
        </Grid>

        <Grid item xs={12}>
          <Tooltip
            title='Specific filter for your visitor
		ex : request_url like "%produits-laitiers%" -> someone that was interested, and visited this kind of page'
          >
            <TextField
              id="sql_condition"
              name="sql_condition"
              label="SQL Filter"
              value={formik.values.sql_condition}
              onChange={formik.handleChange("sql_condition")}
              error={
                formik.touched.sql_condition &&
                Boolean(formik.errors.sql_condition)
              }
              helperText={
                formik.touched.sql_condition && formik.errors.sql_condition
              }
              fullWidth
            />
          </Tooltip>
        </Grid>

        <Grid item xs={10}>
          <Button
            type="submit"
            variant="contained"
            endIcon={<SendIcon />}
            fullWidth
          >
            Next
          </Button>
          <br />
          <br />
        </Grid>

        <Grid item xs={10}>
          <Button variant="contained" fullWidth onClick={props.prevStep}>
            Back
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
export default WFPopulation;
