import * as yup from "yup";
import { useFormik } from "formik";
import { PopulationSourcePicker, BilanType } from "./Bilan";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import {SqlFilter} from "../utils/reusableComponents"

const wamPopulationValidationSchema = yup.object({
  id: yup
    .string()
    .required("An id is required for the audience")
    .min(1, "An id is required for the audience")
    .matches(/^[a-zA-Z0-9]*$/, "ID can only contains letters and numbers"),
});

function BigReport(props: any) {
  const formik = useFormik({
    initialValues: {
      id: "",
      sql_condition: "",
      id_type: "custom_segments",
    },
    validationSchema: wamPopulationValidationSchema,
    onSubmit: (value: any) => {
      value.pop_type = "WAM";
      props.after(value);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1} alignItems="center" justifyContent="center">
            <Grid item xs={12}>
                <PopulationSourcePicker
                        handler={props.sourceHandler}
                        value={BilanType.GLOBAL}
                        availableData=""
                        countryPopulation=""
                        {...props}
                />
            </Grid>

            <Grid item xs={10}>
                <hr
                style={{
                background: "#A5A4A4",
                height: "1px",
                width:"100%",
                border: "none",
                }}
                />
            </Grid>
            
            <Grid item xs={12}>
              <p style={{ fontWeight: 'bold', color: 'red' }}>NOT AVAILABLE AT THE MOMENT</p>
            </Grid>
        </Grid>
    </form>
  );
}

export default BigReport;
