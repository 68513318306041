import React, { useState, useEffect } from "react";

import { useAuth } from "../../contexts/AuthContext";

import Autocomplete from "@mui/material/Autocomplete";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import WAMPopulation from "./WAMPopulation";
import WCMPopulation from "./WCMPopulation";
import WFPopulation from "./WFPopulation";
import CountryPopulation from "./CountryPopulation";
import CustomQueryPopulation from "./CustomQueryPopulation";
import MoonfishPopulation from "./MoonfishPopulation";
// import { BigfishAudienceForm } from "./audiences/BigfishAudienceForm";

const format_date = (date_to_format:any) => {
  const date = date_to_format.toLocaleDateString("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  });
  return date;
};


// all existing audience type, used by SourcePicker
export enum AudienceType {
  WAM = "WAM",
  WCM = "WCM",
  WF = "WF",
  CP = "COUNTRY_POPULATION",
  BF = "BIGFISH",
  MF = "MOONFISH",
  CQ = "CUSTOM_QUERY"
}

export const SourcePicker = (props: any) => {
  return (
    <Select
      labelId="sourcePicker"
      id="sourcePicker"
      label="Audience type"
      onChange={props.handler}
      value={props.value}
      fullWidth
    >
      <MenuItem value={AudienceType.WAM}>Datamining WAM</MenuItem>
      <MenuItem value={AudienceType.WCM}>Datamining WCM</MenuItem>
      <MenuItem value={AudienceType.WF}>WAM Factory Logs</MenuItem>
      <MenuItem value={AudienceType.CP}>Bigsea general taxonomy</MenuItem>
      <MenuItem value={AudienceType.MF}>Moonfish</MenuItem>
      <MenuItem value={AudienceType.CQ}>Beast Mode</MenuItem>
    </Select>
  );
};

const AudienceForm = (props: any) => {
  const [audienceType, setAudienceType] = React.useState(AudienceType.WAM);

  const audienceTypeHandler = (event: any) => {
    setAudienceType(event.target.value);
  };

  if (audienceType === AudienceType.WAM) {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <WAMPopulation
            sourceHandler={audienceTypeHandler}
            addAudience={props.addAudience}
            {...props}
          />
        </Grid>
      </Grid>
    );
  } else if (audienceType === AudienceType.WCM) {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <WCMPopulation
            sourceHandler={audienceTypeHandler}
            addAudience={props.addAudience}
            {...props}
          />
        </Grid>
      </Grid>
    );
  } else if (audienceType === AudienceType.WF) {
    return (
      <Grid container spacing={4} >
        <Grid item xs={12}>
          <WFPopulation
            sourceHandler={audienceTypeHandler}
            addAudience={props.addAudience}
            {...props}
          />
        </Grid>
      </Grid>
    );
  } 
  else if (audienceType === AudienceType.CP) {
    return (
      <Grid container spacing={4} >
        <Grid item xs={12}>
          <CountryPopulation
            sourceHandler={audienceTypeHandler}
            addAudience={props.addAudience}
            {...props}
          />
        </Grid>
      </Grid>
    );
  } 
  
  
  else if (audienceType === AudienceType.CQ) {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <CustomQueryPopulation
            sourceHandler={audienceTypeHandler}
            addAudience={props.addAudience}
            {...props}
          />
        </Grid>
      </Grid>
    );
  }

  else if (audienceType === AudienceType.MF) {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MoonfishPopulation
            sourceHandler={audienceTypeHandler}
            addAudience={props.addAudience}
            country={props.country}
            {...props}
          />
        </Grid>
      </Grid>
    );
  }

  else {
    return <div>Population type not implemented yet</div>;
  }
};

// right part: show all audiences inputed so far
const AudienceList = (props: any) => {
  return (
    <Paper elevation={3}>
      <List dense={true}>
        {props.audienceList.map((audience: any) => (
          <AudienceItem
            deletePopulation={props.deletePopulation}
            audience={audience}
          />
        ))}
      </List>
    </Paper>
  );
};
const AudienceItem = (props: any) => {
  return (
    <ListItem
      secondaryAction={
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => props.deletePopulation(props.audience.uuid)}
        >
          <DeleteIcon />
        </IconButton>
      }
    >
      <ListItemText
        primary={props.audience.population_name}
        secondary={props.audience.pop_type + ' | ' + "Between " + props.audience.date_start + " and " + props.audience.date_end}
      />
    </ListItem>
  );
};

function CIPopulation(props: any) {
  return (
    <div>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        direction="row"
      >
        <Grid item xs={5}>
          <AudienceForm addAudience={props.addAudience} {...props} country={props.country}/>
        </Grid>

        <Grid item xs={5}>
          <AudienceList
            audienceList={props.audienceList}
            deletePopulation={props.deletePopulation}
            
            {...props}
          />
        </Grid>

        <Grid item xs={10}>
          <Button
            variant="contained"
            endIcon={<SendIcon />}
            fullWidth
            onClick={props.nextStep}
          >
            Next
          </Button>
          <br />
          <br />
        </Grid>
        
      </Grid>
    </div>
  );
}

export default CIPopulation;
