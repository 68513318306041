import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    textDecoration: "none",
    boxShadow: "none",
    color: "#ffffff",
  },
});

export default function DenseAppBar() {
  const [error, setError] = useState("");
  const [selectedTab, setSelectedTab] = useState(-1);
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    switch (window.location.pathname) {
      // case "/datafetcher":
      //   if (selectedTab !== 0) {
      //     setSelectedTab(0);
      //   }
      //   break;
      case "/score":
        if (selectedTab !== 0) {
          setSelectedTab(0);
        }
        break;
      case "/score_url":
        if (selectedTab !== 1) {
          setSelectedTab(1);
        }
        break;
      case "/ci":
        if (selectedTab !== 2) {
          setSelectedTab(2);
        }
        break;
      case "/activationreport":
        if (selectedTab !== 3) {
          setSelectedTab(3);
        }
        break;
      case "/create_dashboard":
        if (selectedTab !== 4) {
          setSelectedTab(4);
        }
        break;
      case "/new_client":
        if (selectedTab !== 5) {
          setSelectedTab(5);
        }
        break;

      case "/history":
        if (selectedTab !== 6) {
          setSelectedTab(6);
        }
        break;
      default:
        setSelectedTab(-1);
    }
  });

  const classes = useStyles();

  async function handleLogout() {
    setError("");
    try {
      await logout();
      navigate("/login");
    } catch (error) {
      console.log(error);
      setError(error);
    }
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="sticky">
        <Toolbar variant="dense">
          <Grid
            container
            spacing={4}
            alignItems="center"
            justifyContent="space-between"
            direction="row"
          >
            <Grid item xs={2}>
              <Typography
                className={classes.root}
                component={Link}
                style={{ fontSize: '17px' }}
                to="/"
              >
                Data & Insights App
              </Typography>
            </Grid>

            <Grid item xs={7}>
              <Tabs
                value={selectedTab}
                onChange={(e: any, newValue: any) => {
                  setSelectedTab(newValue);
                }}
                centered
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons = {true}
                allowScrollButtonsMobile
              >
                {/* <Tab label="Datafetcher" component={Link} to="/datafetcher" />
                <Tab label="Consumer Insight" component={Link} to="/ci" /> */}

                <Tab label="Cookies lookalike" component={Link} to="/score" />

                <Tab label="URLs lookalike" component={Link} to="/score_url"/>

                <Tab label="Consumer Insights" component={Link} to="/ci"/>

                <Tab label="Post-campaign reports" component={Link} to="/activationreport" />

                <Tab
                  label="Embed dashboard"
                  component={Link}
                  to="/create_dashboard"
                />
                <Tab
                  label="Add a new client"
                  component={Link}
                  to="/new_client"
                />
                
                <Tab label="History" component={Link} to="/history" />
              </Tabs>
            </Grid>

            <Grid item xs={2} container justifyContent="flex-end">
              {currentUser && (
                <Button variant="contained" onClick={handleLogout}>
                  Log out from {currentUser.email}
                </Button>
              )}

              {/* {!currentUser && (
                <Button
                  color="secondary"
                  component={Link}
                  to="/login"
                  variant="contained"
                >
                  Log In
                </Button>
              )} */}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
