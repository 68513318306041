import React from "react";
import { v4 as uuidv4 } from "uuid";
import { useState, useEffect } from "react";
import ScoreClientSelection from "./ScoreClientSelection";
import ScorePopulation from "./ScorePopulation";
import SegmentSelection from "./SegmentSelection";
import { useAuth } from '../../contexts/AuthContext'
import { useNavigate } from "react-router-dom";
import usePostRequest from "../utils/requests"
import { useSnackbar } from "notistack";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import {now, next3month} from '../utils/utils';

export enum ScoreStep {
  CLIENT_SELECTION = "CLIENT_SELECTION",
  SCORE_POPULATION = "SCORE_POPULATION",
  SEGMENT_SELECTION = "SEGMENT_SELECTION",
}

const steps = ["Setup", "Population creation", "Feature selection"];

function Score(props: any) {
  const [step, setStep] = useState(ScoreStep.CLIENT_SELECTION);
  const [activeStep, setActiveStep] = useState(0);

  const { currentUser } = useAuth();
  const runPostRequest = usePostRequest()

  // components of the json that will be sent to datastore
  const [selectedProject, setSelectedProject] = useState<any>({
  });
  const [selectedSegments, setSelectedSegments] = useState({});
  const [positivePopulation, setPositivePopulation] = useState({})
  const [negativePopulation, setNegativePopulation] = useState({})
  const [requestCountry, setRequestCountry] = useState("")
  
  // one client and its projects
  const [selectedClient, setSelectedClient] = useState({
    projects: [],
    client_name: null,
    uuid: null,
    available_data: {}
  });
  
  const createRequest = async (selectedSegments: any) => {
    setSelectedSegments(selectedSegments)
    // need_df is always true for now
    const metadata = {'client':selectedProject, 'uuid':uuidv4(), state: "SC_NEW_REQUEST",creation_date : now(), user: {uid: currentUser.uid, email:currentUser.email}, need_df: true, "day_30" : false, "day_7" : false, "day_0" : false, "expiration_date" : next3month()}
    const scoreRequest = {'POSITIVE_POP':positivePopulation, 'NEGATIVE_POP':negativePopulation, 'selected_segments':selectedSegments, 'metadata':metadata}
    const messages = {"success": `Score '${selectedProject.score_name}' succesfully created`, "error":`An error occured while creating score '${selectedProject.score_name}'`}
    await runPostRequest(scoreRequest, "/datastore/create/score", "/history", false, messages)
  }
  
  const sendRequest = (scoreRequest: any) => {
    currentUser.getIdToken(true).then(function (idToken: any) {
      fetch("/datastore/create/score", {
        method: "post",
        headers: { Authorization: idToken, "Content-Type": "application/json" },
        body: JSON.stringify(scoreRequest),
      })
        .then((response) => response.json())
    });
  }

  const nextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (step === ScoreStep.CLIENT_SELECTION) {
      return setStep(ScoreStep.SCORE_POPULATION);
    }
    if (step === ScoreStep.SCORE_POPULATION) {
      return setStep(ScoreStep.SEGMENT_SELECTION);
    }
  };

  const prevStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    if (step === ScoreStep.SEGMENT_SELECTION) {
      return setStep(ScoreStep.SCORE_POPULATION);
    }
    if (step === ScoreStep.SCORE_POPULATION) {
      setSelectedClient({
        projects: [],
        client_name: null,
        uuid: null,
        available_data: {}
      })
      return setStep(ScoreStep.CLIENT_SELECTION);
    }
  };

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={10}>
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <div>
          <br />
          <br />
          {step === ScoreStep.CLIENT_SELECTION && (
            <ScoreClientSelection
              nextStep={nextStep}
              prevStep={prevStep}
              selectedClient={selectedClient}
              setSelectedClient={setSelectedClient}
              setSelectedProject={setSelectedProject}
              setRequestCountry={setRequestCountry}
            />
          )}

          {step === ScoreStep.SCORE_POPULATION && (
            <ScorePopulation
            nextStep={nextStep}
            prevStep={prevStep}
            selectedClient={selectedClient}
            setPositivePopulation={setPositivePopulation}
            setNegativePopulation={setNegativePopulation}
            selectedProject={selectedProject}
            />
          )}

          {step === ScoreStep.SEGMENT_SELECTION && (
            <SegmentSelection
              prevStep={prevStep}
              setSelectedSegments={setSelectedSegments}
              createRequest={createRequest}
              country={requestCountry}
            />
          )}
        </div>
      </Grid>
    </Grid>
  );
}

export default Score;
