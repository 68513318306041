import React, { useState, useEffect } from "react";

import Autocomplete from "@mui/material/Autocomplete";
import { useAuth } from "../../contexts/AuthContext";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import SaveSharpIcon from "@mui/icons-material/SaveSharp";
import AddBoxSharpIcon from "@mui/icons-material/AddBoxSharp";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import TuneIcon from '@mui/icons-material/Tune';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Tooltip from "@mui/material/Tooltip";

export const SelectClient = (props: any) => {
  const [clientsList, setClientsList] = useState([]);
  const { currentUser } = useAuth();

  useEffect(() => {
    const initialize = () => {
      // get access token to auth to backend then fetch (with token in header?)
      currentUser.getIdToken(true).then(function (idToken: any) {
        fetch("/field/clients/" + props.country, {
          method: "get",
          headers: { Authorization: idToken },
        })
          .then((response) => response.json())
          .then((responseData) => {
            const sortedClientsList = responseData.sort((a: any, b: any) => {
              const aLabel = getLabel(a).toLowerCase();
              const bLabel = getLabel(b).toLowerCase();
              return aLabel.localeCompare(bLabel);
            });
            setClientsList(responseData);
          });

          
      });
    };
    return initialize();
  }, [props.country]);

  const getLabel = (option: any) => {
    return option.client_name;
  };

  return (
    <Autocomplete
      multiple={props.multiple}
      id="clients"
      options={clientsList}
      getOptionLabel={getLabel}
      filterSelectedOptions
      onChange={(event: any, value: any): void => {
        if (value == null) {
          value = { projects: [], client_name: null };
        }
        props.setSelectedClient(value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Client"
          placeholder="Click to show clients list"
          error={props.error}
          helperText={props.helperText}
          value={props.selectedClient.client_name}
        />
      )}
    />
  );
};

export const SelectProject = (props: any) => {
  const sortedProjectList = props.projectList.sort((a: any, b: any) => {
    return a.project_name.localeCompare(b.project_name);
  });

  return (
    <TextField
      id="project_name"
      select
      label="Project"
      value={props.value}
      onChange={props.onChange}
      error={props.error}
      helperText={props.helperText}
      fullWidth
    >
      {sortedProjectList.map((project: any) => (
        <MenuItem value={project.project_name}>{project.project_name}</MenuItem>
      ))}
    </TextField>
  );
};

export const PopulationName = (props: any) => {
  return (
    <Tooltip title="Population name">
    <TextField
      id="population_name"
      name="Population name"
      label="Population name"
      value={props.value}
      onChange={props.onChange}
      error={props.error}
      helperText={props.helperText}
      fullWidth
    />
  </Tooltip>
  );
};

export const SelectCountry = (props: any) => {
  return (
    <TextField
      id="country"
      select
      label="Country"
      value={props.value}
      onChange={props.onChange}
      error={props.error}
      helperText={props.helperText}
      fullWidth
    >
      <MenuItem selected={true} value={"FR"}>
        France
      </MenuItem>
      <MenuItem value={"DE"}>Germany</MenuItem>
      <MenuItem value={"IT"}>Italy</MenuItem>
      <MenuItem value={"NL"}>Netherlands</MenuItem>
      <MenuItem value={"PT"}>Portugal</MenuItem>
      <MenuItem value={"RU"}>Russia</MenuItem>
      <MenuItem value={"ES"}>Spain</MenuItem>
      <MenuItem value={"GB"}>UK</MenuItem>

    </TextField>
  );
};

export const SelectCountryURL = (props: any) => {
  return (
    <TextField
      id="country"
      select
      label="Country"
      value={props.value}
      onChange={props.onChange}
      error={props.error}
      helperText={props.helperText}
      fullWidth
    >
      <MenuItem selected={true} value={"FR"}>
        France
      </MenuItem>
      <MenuItem value={"PT"}>Portugal</MenuItem>
    </TextField>
  );
};

export const CreateProject = (props: any) => {
  // true if component is in a state that allows user to enter a new project (text field shown)
  // else, false (add button show)
  const [editing, setEditing] = useState(false);
  const [newProject, setNewProject] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const canCreate = newProject != "" && newProject != null && props.client_uuid;

  async function newProjectRequest(
    client_uuid: any,
    project_name: any,
    callback: any
  ) {
    // check project name
    if (!project_name.match(/^[a-zA-Z0-9_]*$/) || project_name.length < 5) {
      enqueueSnackbar(
        "Project name must be at least 5 character long and can only contain letters, numbers or underscores"
      );
      return;
    }

    currentUser.getIdToken(true).then(function (idToken: any) {
      fetch("/datastore/create/project/" + client_uuid, {
        method: "post",
        headers: { Authorization: idToken, "Content-Type": "application/json" },
        body: JSON.stringify({ project_name: project_name }),
      })
        .then((response) => response.json())
        .then((responseData) => {
          callback(responseData);
        });
    });
  }

  function afterCreateProject(response: any) {
    const new_client = response.new_client;
    props.setSelectedClient(new_client);
    enqueueSnackbar(response.message);
    if (response.result == "success") {
      setEditing(!editing);
    }
  }

  return (
    <div>
      {editing && (
        <div>
          <IconButton
            color="primary"
            aria-label="save new project"
            onClick={async () =>
              await newProjectRequest(
                props.client_uuid,
                newProject,
                afterCreateProject
              )
            }
            disabled={!canCreate}
          >
            <SaveSharpIcon />
          </IconButton>

          <IconButton
            color="primary"
            aria-label="cancel"
            onClick={() => setEditing(!editing)}
          >
            <CancelSharpIcon />
          </IconButton>

          <TextField
            id="new_project"
            name="new_project"
            label="New project"
            value={newProject}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setNewProject(event.currentTarget.value)
            }
            fullWidth
          />
        </div>
      )}

      {!editing && (
        <IconButton
          color="primary"
          aria-label="create new project"
          onClick={() => setEditing(!editing)}
          disabled={!props.client_uuid}
        >
          <AddBoxSharpIcon />
        </IconButton>
      )}
    </div>
  );
};

export const SelectWcmID = (props: any) => {
  const [wcmIdList, setWcmIdList] = useState([]);
  const { currentUser } = useAuth();

  useEffect(() => {
    const initialize = () => {
      // get access token to auth to backend then fetch (with token in header?)
      currentUser.getIdToken(true).then(function (idToken: any) {
        fetch("/field/accounts/wcm", {
          method: "get",
          headers: { Authorization: idToken },
        })
          .then((response) => response.json())
          .then((responseData) => {
            const sortedWcmIdList = responseData.ids.sort((a: any, b: any) => {
              const labelA = getLabel(a).toLowerCase();
              const labelB = getLabel(b).toLowerCase();
              if (labelA < labelB) {
                return -1;
              }
              if (labelA > labelB) {
                return 1;
              }
              return 0;
            });
            setWcmIdList(sortedWcmIdList);
          });
      });
    };
    return initialize();
  }, []);

  const getLabel = (option: any) => {
    return `${option.id} - ${option.label}`;
  };

  return (
    <Autocomplete
      multiple={props.multiple}
      id="wcm_accounts"
      options={wcmIdList}
      getOptionLabel={getLabel}
      filterSelectedOptions
      onChange={props.onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label="WCM Account"
          placeholder="Click to show WCM accounts"
          error={props.error}
          helperText={props.helperText}
        />
      )}
    />
  );
};

export const SelectWamID = (props: any) => {
  const [wamIdList, setWamIdList] = useState([]);
  const { currentUser } = useAuth();

  useEffect(() => {
    const initialize = () => {
      // get access token to auth to backend then fetch (with token in header?)
      currentUser.getIdToken(true).then(function (idToken: any) {
        fetch("/field/accounts/wam", {
          method: "get",
          headers: { Authorization: idToken },
        })
          .then((response) => response.json())
          .then((responseData) => {
            const sortedWcmIdList = responseData.ids.sort((a: any, b: any) => {
              const labelA = getLabel(a).toLowerCase();
              const labelB = getLabel(b).toLowerCase();
              if (labelA < labelB) {
                return -1;
              }
              if (labelA > labelB) {
                return 1;
              }
              return 0;
            });
            setWamIdList(responseData.ids);
          });
      });
    };
    return initialize();
  }, []);

  const getLabel = (option: any) => {
    return `${option.id} - ${option.label}`;
  };

  return (
    <Autocomplete
      multiple={props.multiple}
      id="wam_accounts"
      options={wamIdList}
      getOptionLabel={getLabel}
      filterSelectedOptions
      onChange={props.onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label="WAM Account"
          placeholder="Click to show WAM accounts"
        />
      )}
    />
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function FullScreenDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Open full-screen dialog
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Training recap
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          <ListItem button>
            <ListItemText primary="Phone ringtone" secondary="Titania" />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText
              primary="Default notification ringtone"
              secondary="Tethys"
            />
          </ListItem>
        </List>
      </Dialog>
    </div>
  );
}

export const SqlFilter = (props: any) => {
  const [showFilter, setShowFilter] = useState(false);

  return (
    <>
      <IconButton color="primary" onClick={async () => setShowFilter(!showFilter)}>
        <ZoomInIcon />
      </IconButton>

      {showFilter && (
        <Tooltip title={props.title}>
          <TextField
            id={props.id_type}
            name={props.name}
            label={props.label}
            value={props.value}
            onChange={props.onChange}
            error={props.error}
            helperText={props.helperText}
            fullWidth
          />
        </Tooltip>
      )}
    </>
  );
};
