
import { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/styles";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { useSnackbar } from "notistack";

const loginValidationSchema = yup.object({
  email: yup
    .string()
    .default("Enter the email")
    .required("Email is required")
    .min(8)
});

export default function EmailResetPwd(props) {
  const { login, resetPasswordEmail } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      const login_returned = await resetPasswordEmail(
        formik.values.email
      );
      enqueueSnackbar(`An email has been sent to ${formik.values.email}`);
      
    } catch (error) {
      setError("Failed to reset password");
    }
  }

  const formik = useFormik({
    initialValues: {
      email: null
    },
    validationSchema: loginValidationSchema,
    onSubmit: (value) => {
      console.log("Submitted reset form");
    },
  });

  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Avatar sx={{ m: 1 }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Reset password
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={formik.values.email}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Send email
        </Button>

        <Grid container>
          <Grid item xs>
            <Link
              href="#"
              variant="body2"
              onClick={() => props.setResettingPwd(!props.resettingPwd)}
            >
              Sign In
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
