import { useState, useEffect, forwardRef } from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import DoneIcon from "@mui/icons-material/Done";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import UpdateIcon from "@mui/icons-material/Update";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { useSnackbar } from "notistack";
import StopIcon from "@mui/icons-material/Stop";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import ErrorIcon from "@mui/icons-material/Error";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import LoopIcon from "@mui/icons-material/Loop";
import Tooltip from "@mui/material/Tooltip";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import InsightsSharpIcon from '@mui/icons-material/InsightsSharp';
const STATE_SHOW_MAPPING_BUTTON = [
  "CI_DONE"
];

const renderStateIcon = (params: any) => {
  return (
    <>
      {params.row.metadata.state == "REGION_REPORT_NEW_REQUEST" && (
        <Tooltip title="This report request is currently being processed">
          <LoopIcon />
        </Tooltip>
      )}
      {params.row.metadata.state == "REGION_REPORT_FORMAT_DONE" && (
        <Tooltip title="This report request is currently being processed">
          <LoopIcon />
        </Tooltip>
      )}
      {params.row.metadata.state == "REGION_REPORT_RUNNING" && (
        <Tooltip title="Request is currently running">
          <HourglassTopIcon />
        </Tooltip>
      )}

      {params.row.metadata.state == "REGION_REPORT_DONE" && (
        <Tooltip title="">
          <DoneIcon />
        </Tooltip>
      )}

      {params.row.metadata.state == "REGION_REPORT_FORMAT_ERROR" && (
        <Tooltip title="A format error occured">
          <ErrorIcon />
        </Tooltip>
      )}

      {params.row.metadata.state == "REGION_REPORT_JOB_ERROR" && (
        <Tooltip title="A job error occured">
          <ErrorIcon />
        </Tooltip>
      )}
    </>
  );
};

export function RenderMappingLink(props: any) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton
        onClick={handleClickOpen}
        disabled={
          !STATE_SHOW_MAPPING_BUTTON.includes(props.params.metadata.state)
        }
      >
        <AnalyticsIcon />
      </IconButton>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Consumer Insight
            </Typography>
          </Toolbar>
        </AppBar>
        <iframe
          name={`recap-page`}
          frameBorder="0"
          src={`/ci/mapping/${props.uuid}`}
          style={{
            display: "block",
            background: "#1F3B50",
            height: "calc(100vh - 60px)",
            width: "calc(100vw - 0px)",
            margin: "auto",
            border: "none",
          }}
        ></iframe>
      </Dialog>
    </div>
  );
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ReportHistory(props: any) {
  const { currentUser } = useAuth();
  const [requestList, setRequestList] = useState([]);
  const [historyLoading, setHistoryLoading] = useState(true);
  const [needReload, setNeedReload] = useState(true);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      editable: false,
      align: "center",
      headerAlign: "center",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.UUID}`,
      flex: 0.1,
    },
    {
      field: "type",
      headerName: "Report type",
      editable: false,
      align: "center",
      headerAlign: "center",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row['Report Information'].report_type}`,
      flex: 0.3,
    },
    {
        field: "creation_date",
        headerName: "Creation time",
        editable: false,
        flex: 0.4,
        align: "center",
        headerAlign: "center",
        valueGetter: (params: GridValueGetterParams) =>
          `${params.row.metadata.creation_date}`,
      },
    {
        field: "name",
        headerName: "Report name",
        editable: false,
        align: "center",
        headerAlign: "center",
        valueGetter: (params: GridValueGetterParams) =>
          `${params.row.Report_Name}`,
        flex: 0.5,
    },
    {
        field: "range date",
        headerName: "Custom range date",
        editable: false,
        align: "center",
        headerAlign: "center",
        valueGetter: (params: GridValueGetterParams) =>
          `${params.row['Report Information'].date_start.split("T")[0]} to \n 
          ${params.row['Report Information'].date_end.split("T")[0]}`,
        flex: 0.9,
    },
    {
      field: "advertiser_id",
      headerName: "Advertiser ID",
      editable: false,
      flex: 0.4,
      align: "center",
      headerAlign: "center",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row['Report Information'].adv_id}`,
    },
    {
        field: "io_id",
        headerName: "Insertion Order ID",
        editable: false,
        flex: 0.4,
        align: "center",
        headerAlign: "center",
        valueGetter: (params: GridValueGetterParams) =>
          `${params.row['Report Information'].io_id}`,
    },
    {
        field: "budget",
        headerName: "Budget",
        editable: false,
        flex: 0.4,
        align: "center",
        headerAlign: "center",
        valueGetter: (params: GridValueGetterParams) =>
          `${params.row['Report Information'].budget}`,
    },
    {
      field: "email",
      headerName: "Owner",
      editable: false,
      flex: 0.7,
      align: "center",
      headerAlign: "center",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.metadata.user.email}`,
    },
    {
      field: "state",
      headerName: "Status",
      editable: false,
      flex: 0.2,
      align: "center",
      headerAlign: "center",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.metadata.state}`,
      renderCell: renderStateIcon,
    }
  ];

  useEffect(() => {
    const initialize = () => {
      // get access token to auth to backend then fetch (with token in header?)
      currentUser.getIdToken(true).then(function (idToken: any) {
        fetch("/datastore/get/requests/all/activation_report", {
          method: "post",
          headers: {
            Authorization: idToken,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((responseData) => {
            console.log(responseData.requests)
            setRequestList(responseData.requests);
            setHistoryLoading(false);
          });
      });
    };
    return initialize();
  }, [needReload]);

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={10}>
        {!historyLoading && (
          <div style={{ height: 800, width: "100%" }}>
            <DataGrid
              rows={requestList}
              columns={columns}
              pageSize={30}
              disableSelectionOnClick
              getRowId={(row) => row.metadata.uuid}
              initialState={{
                sorting: {
                  sortModel: [{ field: "creation_date", sort: "desc" }],
                },
              }}
            />
          </div>
        )}

        {historyLoading && (
          <Grid item xs={10} alignContent="center">
            <CircularProgress />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default ReportHistory;
