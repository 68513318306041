import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useParams,
} from "react-router-dom";

import Datafetcher from "./components/datafetcher/Datafetcher";
import CI from "./components/ci/CI";
import Score from "./components/score/Score";
import Report from "./components/activation_report/Report";
import ScoreUrl from "./components/score/ScoreUrl";
import Login from "./components/ui/Login";
import { AuthProvider } from "./contexts/AuthContext";
import PrivateRoute from "./components/ui/PrivateRoute";
import App from "./App";
import History from "./components/history/History";
import Homepage from "./components/ui/Homepage";
import NewClient from "./components/utils/NewClient";
import DashViewer from "./components/dashboard/DashViewer";
import DashCreation from "./components/dashboard/DashCreation";

const rootElement = document.getElementById("root");

render(
  <BrowserRouter>
    <AuthProvider>
      <Routes>
        <Route path="/dashboards/viewer/:slug" element={<DashViewer />} />

        <Route path="/" element={<App />}>
          <Route path="login" element={<Login />}></Route>

          <Route path="/" element={<Navigate replace to="/home" />} />

          {/* <Route
            path="/datafetcher"
            element={
              <PrivateRoute>
                <Datafetcher />
              </PrivateRoute>
            }
          /> */}

          {/* <Route
            path="/ci"
            element={
              <PrivateRoute>
                <CI />
              </PrivateRoute>
            }
          /> */}

          <Route
            path="/score"
            element={
              <PrivateRoute>
                <Score />
              </PrivateRoute>
            }
          />
          <Route
            path="/activationreport"
            element={
              <PrivateRoute>
                <Report />
              </PrivateRoute>
            }
          />

<Route
            path="/score_url"
            element={
              <PrivateRoute>
                <ScoreUrl />
              </PrivateRoute>
            }
          />

          {
            <Route
              path="/ci"
              element={
                <PrivateRoute>
                  <CI />
                </PrivateRoute>
              }
            />
          }

          <Route
            path="/history"
            element={
              <PrivateRoute>
                <History />
              </PrivateRoute>
            }
          />

          <Route
            path="/home"
            element={
              <PrivateRoute>
                <Homepage />
              </PrivateRoute>
            }
          />

          <Route
            path="/new_client"
            element={
              <PrivateRoute>
                <NewClient />
              </PrivateRoute>
            }
          />

          <Route
            path="/create_dashboard"
            element={
              <PrivateRoute>
                <DashCreation />
              </PrivateRoute>
            }
          />

          {/* <Route path="/dashboards/viewer/:slug" element={<DashViewer />} /> */}

          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>Invalid path</p>
              </main>
            }
          />
        </Route>
      </Routes>
    </AuthProvider>
  </BrowserRouter>,
  rootElement
);
