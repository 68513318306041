import * as yup from "yup";
import { useFormik } from "formik";
import { PopulationType, PopulationSourcePicker } from "./CI";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { SqlFilter, PopulationName } from "../utils/reusableComponents";
import {
  populationNameValidator,
  startValueValidator,
  endValueValidator,
} from "../validators";

const cqPopulationValidationSchema = yup.object({
  population_name: populationNameValidator,
});

function CustomQueryPopulation(props: any) {
  const formik = useFormik({
    initialValues: {
      sql_condition: "",
      population_name: "",
    },
    validationSchema: cqPopulationValidationSchema,
    onSubmit: (value: any) => {
      value.pop_type = "CQ";
      console.log("Calling addPopulation() : " + JSON.stringify(value));
      props.addPopulation(value);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={4} alignItems="center" justifyContent="center">
        <Grid item xs={10}>
          <Grid
            container
            spacing={4}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12}>
              <PopulationName
                value={formik.values.population_name}
                onChange={formik.handleChange("population_name")}
                error={
                  formik.touched.population_name &&
                  Boolean(formik.errors.population_name)
                }
                helperText={
                  formik.touched.population_name &&
                  formik.errors.population_name
                }
              />
            </Grid>
            <Grid item xs={12}>
              <PopulationSourcePicker
                handler={props.sourceHandler}
                value={PopulationType.CQ}
                availableData={props.selectedClient.available_data}
                countryPopulation={props.selectedProject.country}
                {...props}
              />
            </Grid>

            <Grid item xs={12}>
              <Tooltip
                title='Full custom population, we need at first 3 columns : aud_weboid, timehit, audience_name
		ex : weboid as aud_weboid, UNIX_MILLIS(TIMESTAMP "2008-12-25 15:30:00+00") as timehit, "POSITIVE_POP" as audience_name
		weboid as aud_weboid, UNIX_MILLIS(timestamp) as timehit, "NEGATIVE_POP" as audience_name'
              >
                <TextField
                  id="sql_condition"
                  name="sql_condition"
                  label="Custom query"
                  value={formik.values.sql_condition}
                  onChange={formik.handleChange("sql_condition")}
                  error={
                    formik.touched.sql_condition &&
                    Boolean(formik.errors.sql_condition)
                  }
                  helperText={
                    formik.touched.sql_condition && formik.errors.sql_condition
                  }
                  fullWidth
                  multiline={true}
                  rows={12}
                />
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={2}>
          <Button type="submit" variant="contained" endIcon={<SendIcon />}>
            Add
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default CustomQueryPopulation;
