import Grid from "@mui/material/Grid";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import * as yup from "yup";
import { useFormik } from "formik";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { SelectTaxos } from "../datafetcher/DatafetcherFeatures";

const segmentSelectionValidationSchema = yup.object({});

function SegmentSelection(props: any) {
  const formik = useFormik({
    initialValues: {
      bigsea_taxo: true,
      lifestages: true,
      conso_resp: true,
      top_100: true,
      geoloc: true,
      webnav: true,
      taxosList: [],
    },
    validationSchema: segmentSelectionValidationSchema,
    onSubmit: async (value: any) => {
      props.setSelectedSegments(value);
      props.nextStep();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={4} alignItems="center" justifyContent="center">
        <Grid item xs={10}>
          <FormGroup>
            <FormControlLabel
              control={<Switch defaultChecked />}
              label="Bigsea General Taxonomy"
              value={formik.values.bigsea_taxo}
              onChange={formik.handleChange("bigsea_taxo")}
            />
          </FormGroup>
        </Grid>

        {["FR", "ES", "PT", "IT", "RU"].includes(props.country) && (
          <Grid item xs={10}>
            <FormGroup>
              <FormControlLabel
                control={<Switch defaultChecked={true} />}
                label="Life Stages"
                value={formik.values.lifestages}
                onChange={formik.handleChange("lifestages")}
              />
            </FormGroup>
          </Grid>
        )}

        {["FR", "ES", "IT", "DE", "UK"].includes(props.country) && (
          <Grid item xs={10}>
            <FormGroup>
              <FormControlLabel
                control={<Switch defaultChecked={true} />}
                label="Responsible consumption"
                value={formik.values.conso_resp}
                onChange={formik.handleChange("conso_resp")}
              />
            </FormGroup>
          </Grid>
        )}

        {["FR", "ES", "IT", "RU", "UK","DE"].includes(props.country) && (
          <Grid item xs={10}>
            <FormGroup>
              <FormControlLabel
                control={<Switch defaultChecked={true} />}
                label="Top 100 Brands"
                value={formik.values.top_100}
                onChange={formik.handleChange("top_100")}
              />
            </FormGroup>
          </Grid>
        )}

        {["FR"].includes(props.country) && (
          <Grid item xs={10}>
            <FormGroup>
              <FormControlLabel
                control={<Switch defaultChecked={true} />}
                label="Cookie Geolocalisation"
                value={formik.values.geoloc}
                onChange={formik.handleChange("geoloc")}
              />
            </FormGroup>
          </Grid>
        )}

        {["FR"].includes(props.country) && (
          <Grid item xs={10}>
            <FormGroup>
              <FormControlLabel
                control={<Switch defaultChecked={true} />}
                label="Browsing history"
                value={formik.values.webnav}
                onChange={formik.handleChange("webnav")}
              />
            </FormGroup>
          </Grid>
        )}

        <Grid item xs={10}>
          <SelectTaxos
            onChange={(event: any, value: any): void => {
              formik.setFieldValue("taxosList", value);
            }}
            multiple={true}
            country={props.country}
          />
        </Grid>

        <Grid item xs={10}>
          <Button
            variant="contained"
            endIcon={<SendIcon />}
            fullWidth
            type="submit"
          >
            Next
          </Button>
          <br />
          <br />
        </Grid>

        <Grid item xs={10}>
          <Button variant="contained" fullWidth onClick={props.prevStep}>
            Back
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default SegmentSelection;
