import Grid from "@mui/material/Grid";
import Population from "./Population";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stack from "@mui/material/Stack";

function ScorePopulation(props: any) {
  const useStyles = makeStyles({});
  const classes = useStyles();

  // true if current population being choosen is the positive one, else false
  const [currentPopulationType, setCurrentPopulationType] = useState(true);

  function afterPositive(value: any) {
    setCurrentPopulationType(false);
    props.setPositivePopulation(value);
  }

  function afterNegative(value: any) {
    props.setNegativePopulation(value);
    props.nextStep();
  }

  return (
    <div>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        direction="row"
      >
        {currentPopulationType && (
          <Grid item xs={6}>
            <Stack spacing={2} alignItems="center">
              <Grid item xs={12}>
                <h1>Target population</h1>
              </Grid>
              <Grid item xs={12}>
                <h5>
                  The target population is the positive population from which we
                  will learn to find out the weak signals to differentiate them
                  among the reference population. For Example : subscriber WAM
                  audience (target population) vs France (reference population){" "}
                </h5>
              </Grid>

              <Grid item xs={6}>
                <Population
                  type="positive"
                  after={afterPositive}
                  selectedClient={props.selectedClient}
                  prevStep={props.prevStep}
                  selectedProject={props.selectedProject}
                />
              </Grid>
            </Stack>
          </Grid>
        )}

        {!currentPopulationType && (
          <Grid item xs={6}>
            <Stack spacing={2} alignItems="center">
              <Grid item xs={12}>
                <h1>Reference population</h1>
              </Grid>

              <Grid item xs={12}>
                <h5>
                  The reference population is the negative average population.
                  For Example : subscriber WAM audience (target population) vs
                  France (reference population){" "}
                </h5>
              </Grid>

              <Grid item xs={6}>
                <Population
                  type="negative"
                  after={afterNegative}
                  selectedClient={props.selectedClient}
                  prevStep={props.prevStep}
                  selectedProject={props.selectedProject}
                />
              </Grid>
            </Stack>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default ScorePopulation;
