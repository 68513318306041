import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useFormik } from "formik";
import * as yup from "yup";
import { PopulationSourcePicker, PopulationType } from "./Population";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { SqlFilter } from "../utils/reusableComponents";

const wcmPopulationValidationSchema = yup.object({
  wcm_id: yup
    .string()
    .min(1, "An id is required")
    .matches(
      /^([0-9]+(\.[0-9]+)?(,[0-9]+(\.[0-9]+)?)*,?)+$/,
      "ID can only contain numbers and decimals separated by commas"
    ),
});

// const wcmPopulationValidationSchema = yup.object({});

function WCMPopulation(props: any) {
  const formik = useFormik({
    initialValues: {
      wcm_action: "impressionvisibility",
      wcm_id: "",
      sql_condition: "",
    },
    validationSchema: wcmPopulationValidationSchema,
    onSubmit: (value: any) => {
      value.pop_type = "WCM";
      props.after(value);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={4} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <PopulationSourcePicker
            handler={props.sourceHandler}
            value={PopulationType.WCM}
            availableData={props.selectedClient.available_data}
            countryPopulation={props.selectedProject.country}
            {...props}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="wcm_action"
            select
            label="Action WCM"
            value={formik.values.wcm_action}
            onChange={formik.handleChange("wcm_action")}
            helperText="Select a WCM action"
            fullWidth
          >
            <MenuItem value={"impression"}>Impression</MenuItem>
            <MenuItem value={"click"}>Click</MenuItem>
            <MenuItem value={"conversion"}>Conversion</MenuItem>
          </TextField>
        </Grid>

        
        {formik.values.wcm_action == "conversion" && (
          <Grid item xs={12}>
            <Tooltip title='Choose one or more conversion Tag ID separated with an "," ex: 12,2,35,4'>
              <TextField
                id="wcm_id"
                name="wcm_id"
                label="ID"
                value={formik.values.wcm_id}
                onChange={formik.handleChange("wcm_id")}
                error={formik.touched.wcm_id && Boolean(formik.errors.wcm_id)}
                helperText={formik.touched.wcm_id && formik.errors.wcm_id}
                fullWidth
              />
            </Tooltip>
          </Grid>
        )}

        <Grid item xs={12}>
          <SqlFilter
            title='More specific filter for your condition (ex: techno like "%Win10%") or intersection with one or more WAM audiences(ex: audiences like "%123456%")'
            id="sql_condition"
            name="sql_condition"
            label="SQL Filter (optional)"
            value={formik.values.sql_condition}
            onChange={formik.handleChange("sql_condition")}
            error={
              formik.touched.sql_condition &&
              Boolean(formik.errors.sql_condition)
            }
            helperText={
              formik.touched.sql_condition && formik.errors.sql_condition
            }
          ></SqlFilter>
        </Grid>

        <Grid item xs={10}>
          <Button
            type="submit"
            variant="contained"
            endIcon={<SendIcon />}
            fullWidth
          >
            Next
          </Button>
          <br />
          <br />
        </Grid>

        <Grid item xs={10}>
          <Button variant="contained" fullWidth onClick={props.prevStep}>
            Back
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
export default WCMPopulation;
