import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import Grid from "@mui/material/Grid";
import ScoreHistory from "./ScoreHistory";
import DashboardHistory from "./DashboardHistory";
import CIHistory from "./CIHistory";
import DatafetcherHistory from "./DatafetcherHistory";
import ScoreUrlHistory from "./ScoreUrlHistory"
import ReportHistory from "./ReportHistory"
import Button from "@mui/material/Button";

export enum RequestType {
  SCORE = "SCORE",
  CI = "CI",
  DATAFETCHER = "DATAFETCHER",
  DASHBOARD = "DASHBOARD",
  SCORE_URL = "SCORE_URL",
  REPORT = "REPORT"
}

function History(props: any) {
  const { currentUser } = useAuth();

  const [currentRequestType, setCurrentRequestType] = React.useState(
    RequestType.SCORE
  );

  return (
    <Grid container alignItems="center" justifyContent="center" spacing={7.5}>
      {/* <Grid item xs={2}>
        <Button
          variant="contained"
          { ...( currentRequestType == RequestType.DATAFETCHER && { variant: "outlined" } ) }
          fullWidth
          onClick={(e) => setCurrentRequestType(RequestType.DATAFETCHER)}
        >
          Datafetcher
        </Button>
      </Grid> */}

      <Grid item xs={2}>
        <Button
          variant="contained"
          {...(currentRequestType == RequestType.SCORE && {
            variant: "outlined",
          })}
          fullWidth
          onClick={(e) => setCurrentRequestType(RequestType.SCORE)}
          sx={{ fontSize: '13px', minWidth: '120px', minHeight: '60px' }}
        >
          Cookies lookalike
        </Button>
      </Grid>

      <Grid item xs={2}>
        <Button
          variant="contained"
          {...(currentRequestType == RequestType.SCORE_URL && {
            variant: "outlined",
          })}
          fullWidth
          onClick={(e) => setCurrentRequestType(RequestType.SCORE_URL)}
          sx={{ fontSize: '13px', minWidth: '120px', minHeight: '60px' }}
        >
          URLs lookalike
        </Button>
      </Grid>

      <Grid item xs={2}>
        <Button
          variant="contained"
          {...(currentRequestType == RequestType.CI && {
            variant: "outlined",
          })}
          fullWidth
          onClick={(e) => setCurrentRequestType(RequestType.CI)}
          sx={{ fontSize: '13px', minWidth: '120px', minHeight: '60px' }}
        >
          Consumer Insights
        </Button>
      </Grid>

      <Grid item xs={2}>
        <Button
          variant="contained"
          {...(currentRequestType == RequestType.REPORT && {
            variant: "outlined",
          })}
          fullWidth
          onClick={(e) => setCurrentRequestType(RequestType.REPORT)}
          sx={{ fontSize: '12px', minWidth: '120px', minHeight: '60px' }}
        >
          Regional Post-campaign reports
        </Button>
      </Grid>

      <Grid item xs={2}>
        <Button
          variant="contained"
          {...(currentRequestType == RequestType.DASHBOARD && {
            variant: "outlined",
          })}
          fullWidth
          onClick={(e) => setCurrentRequestType(RequestType.DASHBOARD)}
          sx={{ fontSize: '13px', minWidth: '120px', minHeight: '60px' }}
        >
          Embed dashboard
        </Button>
      </Grid>

      <Grid item xs={12}>
        <div>
          <br />
          <br />
          {currentRequestType === RequestType.SCORE && <ScoreHistory />}

          {currentRequestType === RequestType.CI && <CIHistory />}

          {currentRequestType === RequestType.DATAFETCHER && (
            <DatafetcherHistory />
          )}

          {currentRequestType === RequestType.DASHBOARD && <DashboardHistory />}

          {currentRequestType === RequestType.DATAFETCHER && (
            <DatafetcherHistory />
          )}

          {currentRequestType === RequestType.SCORE_URL && <ScoreUrlHistory />}

          {currentRequestType === RequestType.REPORT && (
            <ReportHistory />
          )}
        </div>
      </Grid>
    </Grid>
  );
}

export default History;
