function now() {
  var today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1).toString().padStart(2, "0") + "-" + today.getDate().toString().padStart(2, "0");
  var time =
    today.getHours().toString().padStart(2, "0") + ":" + today.getMinutes().toString().padStart(2, "0") + ":" + today.getSeconds().toString().padStart(2, "0");
  var dateTime = date + " " + time;
  return dateTime;
}

export {now};

function next3month() {
  var today = new Date();
  var future = new Date(today.getFullYear(), today.getMonth() + 3, today.getDate(), today.getHours(), today.getMinutes(), today.getSeconds());
  var date =
    future.getFullYear() + "-" + (future.getMonth() + 1).toString().padStart(2, "0") + "-" + future.getDate().toString().padStart(2, "0");
  var time =
    future.getHours().toString().padStart(2, "0") + ":" + future.getMinutes().toString().padStart(2, "0") + ":" + future.getSeconds().toString().padStart(2, "0");
  var dateTime = date + " " + time;
  return dateTime;
}

export {next3month};

