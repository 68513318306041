// @ts-nocheck
import React, { useState, useEffect } from "react";

import { useAuth } from "../../contexts/AuthContext";
import IconButton from "@mui/material/IconButton";
import * as yup from "yup";
import { useFormik } from "formik";
import Stack from "@mui/material/Stack";
import SendIcon from "@mui/icons-material/Send";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ChangeHistoryOutlinedIcon from "@mui/icons-material/ChangeHistoryOutlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CropSquareOutlinedIcon from "@mui/icons-material/CropSquareOutlined";
import TextField from "@mui/material/TextField";
import { HuePicker, TwitterPicker, CompactPicker } from "react-color";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import PaletteOutlinedIcon from "@mui/icons-material/PaletteOutlined";
import { useSnackbar } from "notistack";

// const wamPopulationValidationSchema = yup.object({
//   template_name: yup
//   .string()
//   .required("An id is required for the audience")
//   .min(1, "An id is required for the audience")
//   .matches(/^[a-zA-Z0-9]*$/, "ID can only contains letters and numbers"),
//   template_theme: yup
//     .string()
//     .required("An id is required for the audience")
//     .min(1, "An id is required for the audience")
//     .matches(/^[a-zA-Z0-9]*$/, "ID can only contains letters and numbers"),
// });

function DashboardTemplate(props: any) {
  const formik = useFormik({
    initialValues: {
      theme: "dark",
      name: "template_ci_fr",
    },
    // validationSchema: wamPopulationValidationSchema,
    onSubmit: (value: any) => {
      console.log("Submitted value: " + value)
      props.createRequest(value);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={4} alignItems="center" justifyContent="center">
        <Grid item xs={10}>
          <Grid
            container
            spacing={4}
            alignItems="center"
            justifyContent="center"
          >

            <Grid item xs={10}>
              <Select
                labelId="templatePicker"
                id="templatePicker"
                label="Dashboard template"
                onChange={formik.handleChange("name")}
                value={formik.values.name}
                fullWidth
              > 
                <MenuItem value={"template_ci_fr"}>Template CI FR</MenuItem>
                <MenuItem value={"template_ci_activation"}>Template CI Activation</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={10}>
              <Select
                labelId="themePicker"
                id="themePicker"
                label="Dashboard theme"
                displayEmpty={true}
                onChange={formik.handleChange("theme")}
                value={formik.values.theme}
                fullWidth
              >
 
                <MenuItem value={"dark"}>Dark</MenuItem>
                <MenuItem value={"light"}>Light</MenuItem>


              </Select>
            </Grid>



          </Grid>
        </Grid>

        <Grid item xs={10}>
          <Button type="submit" fullWidth variant="contained" endIcon={<SendIcon />}>
            Create CI
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default DashboardTemplate;
