import { useAuth } from "../../contexts/AuthContext";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import {now} from '../utils/utils';

// expect a request with format:  {
//   "status": "success" or "error",
//   "data": {
//     /* Application-specific data */
//   },
//   "message": null /* Or optional success message */
// }

// TODO: check return code for errors
const usePostRequest = () => {
  const { currentUser } = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const createRequest = async (data: any, endpoint: any, navigate_to: any, set_metadata:any, messages:any) => {
    // if display_snackbar=true, a snackbar with the 'message' field of the api response will be displayed
    // if navigate_to is not null, user will be redirected there
    currentUser.getIdToken(true).then(function (idToken: any) {
      if(set_metadata){
        data.metadata = { user: {uid: currentUser.uid, email:currentUser.email}, creation_date:now()}
      }
    
      fetch(endpoint, {
        method: "post",
        headers: { Authorization: idToken, "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((responseData) => {

          // if messages are provided they will be used in the snackbar, else the message field of response will be used
          if (messages != null){
            enqueueSnackbar(messages[responseData.status]);
          }else{
            enqueueSnackbar(responseData.message);
          }          

          // redirect only if success
          if (responseData.status == "success" && navigate_to != null) {
            navigate(navigate_to);
          }

        });
    });
  };
  return createRequest;
};

export default usePostRequest;
