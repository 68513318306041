import React from "react";
import { v4 as uuidv4 } from "uuid";
import { useState, useEffect } from "react";
import CIClientSelection from "../ci/CIClientSelection";
import ScorePopulation from "../score/ScorePopulation";
import CIPopulation from "./CIPopulation";
import SegmentSelection from "./SegmentSelection";
import DashboardTemplate from "./DashboardTemplate";
import Mappings from "./Mappings";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import usePostRequest from "../utils/requests";
import { useSnackbar } from "notistack";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { format } from "date-fns";

import { now } from "../utils/utils";
import { RenderTrainingLink } from "../history/ScoreHistory";

export enum CIStep {
  CLIENT_SELECTION = "CLIENT_SELECTION",
  CI_POPULATION = "CI_POPULATION",
  SEGMENT_SELECTION = "SEGMENT_SELECTION",
  MAPPING_SELECTION = "MAPPING_SELECTION",
  DASHBOARD_TEMPLATE = "DASHBOARD_TEMPLATE",
}

export enum PopulationType {
  WAM = "WAM",
  WCM = "WCM",
  WF = "WF",
  CQ = "CUSTOM_QUERY",
  CP = "COUNTRY_POPULATION",
  MF = "MOONFISH",
}

const countryToLabel: any = {
  FR: "France",
  ES: "Spain",
  PT: "Portugal",
  NL: "Netherlands",
  DE: "Germany",
  GB: "UK",
  RU: "Russia",
  IT: "Italy",
};

const sourceTypeLabel: any = {
  WAM: "Datamining WAM",
  WCM: "Datamining WCM",
  WF: "Wamfactory Logs",
};

export const PopulationSourcePicker = (props: any) => {
  return (
    <Select
      labelId="sourcePicker"
      id="sourcePicker"
      label="Population type"
      onChange={props.handler}
      value={props.value}
      fullWidth
    >
      {/* Only show field if data is available */}
      {Object.entries(props.availableData).map(
        ([key, value]) =>
          value && <MenuItem value={key}>{sourceTypeLabel[key]}</MenuItem>
      )}
      <MenuItem value={PopulationType.CQ}>Beast mode</MenuItem>
      <MenuItem value={PopulationType.MF}>Moonfish</MenuItem>

      {
        <MenuItem value={PopulationType.CP}>
          {countryToLabel[props.countryPopulation]}
        </MenuItem>
      }
    </Select>
  );
};

const steps = [
  "Setup",
  "Population creation",
  "Feature selection",
  "Mappings",
  "Dashboard template",
];

function CI(props: any) {
  const [step, setStep] = useState(CIStep.CLIENT_SELECTION);
  const [activeStep, setActiveStep] = useState(0);

  const [audienceList, setAudienceList] = React.useState<any>([]);
  const [mappingList, setMappingList] = React.useState<any>([]);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [requestCountry, setRequestCountry] = useState("");

  const [templateName, setTemplateName] = useState("template1");
  const [templateTheme, setTemplateTheme] = useState("dark");

  const { currentUser } = useAuth();
  const runPostRequest = usePostRequest();

  // components of the json that will be sent to datastore
  const [selectedProject, setSelectedProject] = useState<any>({});
  const [selectedSegments, setSelectedSegments] = useState({});

  const addPopulation = (newPopulation: any) => {
    console.log("New population: " + JSON.stringify(newPopulation))

    if (newPopulation !== null) {
      // check if population name is already taken
      if (
        audienceList.filter(
          (e: any) => e.population_name === newPopulation.population_name
        ).length > 0
      ) {
        return false;
      }
      if ("date_start" in newPopulation && "date_end" in newPopulation) {
        if ( ! (typeof newPopulation.date_start === 'string' || newPopulation.date_start instanceof String)){
          newPopulation.date_start = format(newPopulation.date_start, "yyyyMMdd");
          newPopulation.date_end = format(newPopulation.date_end, "yyyyMMdd");
        }
      }

      newPopulation.uuid = uuidv4();
      setAudienceList([...audienceList, newPopulation]);
      enqueueSnackbar(`Population '${newPopulation.population_name}' added`);
    }
    console.log("New pop: " + JSON.stringify(newPopulation));
    return true
  };

  const addMapping = (mappingName: any, newMapping: any) => {
    if (
      mappingName == null ||
      mappingName === "" ||
      mappingName === undefined
    ) {
      enqueueSnackbar(`Mapping name is missing`);
      return;
    }

    if (mappingName.length < 5) {
      enqueueSnackbar(`Mapping name must be at least 5 characters long`);
      return;
    }
    console.log("Mapping name: " + mappingName);
    var re = /^[a-zA-Z0-9_]+$/;
    if (!re.test(mappingName)) {
      enqueueSnackbar(
        `Mapping name can only contain numbers, letters and underscores`
      );
      return;
    }

    if (newMapping !== null) {
      // check if mapping name is already taken
      if (
        mappingList.filter((e: any) => e.mapping_name === mappingName).length >
        0
      ) {
        enqueueSnackbar(`Mapping name '${mappingName}' already taken`);
        return;
      }

      // only keep few useful fields
      var mappingCopy: any = [];
      newMapping.map((population: any) => {
        mappingCopy.push({
          population_name: population.population_name,
          color: population.color,
          shape: population.shape,
        });
      });

      var mapping = {
        populations: mappingCopy,
        uuid: uuidv4(),
        mapping_name: mappingName,
      };

      setMappingList([...mappingList, mapping]);
      enqueueSnackbar(`Mapping '${mappingName}' added`);
      console.log("Current mappings: " + JSON.stringify(mappingList));
    }
  };

  const deletePopulation = (uuid: any) => {
    setAudienceList(
      audienceList.filter((audience: any) => audience.uuid !== uuid)
    );
    enqueueSnackbar(`Population deleted`);
  };

  const deleteMapping = (uuid: any) => {
    setMappingList(mappingList.filter((mapping: any) => mapping.uuid !== uuid));
    enqueueSnackbar(`Mapping deleted`);
  };

  // one client and its projects
  const [selectedClient, setSelectedClient] = useState({
    projects: [],
    client_name: null,
    uuid: null,
    available_data: {},
  });

  const createRequest = async (value:any) => {
    const metadata = {
      client: selectedProject,
      uuid: uuidv4(),
      state: "CI_NEW_REQUEST",
      creation_date: now(),
      user: { uid: currentUser.uid, email: currentUser.email },
    };

    // deep copy state
    let copyAudiencesList = JSON.parse(JSON.stringify(audienceList));

    copyAudiencesList.map((audience: any) => {
      delete audience["color"];
      delete audience["shape"];
      return audience;
    });

    const ciRequest = {
      metadata: metadata,
      selected_segments: selectedSegments,
      population_list: copyAudiencesList,
      mapping_list: mappingList,
      dashboard: {"theme": value.theme, "name": value.name}
    };

    const messages = {
      success: `CI '${selectedProject.ci_name}' succesfully created`,
      error: `An error occured while creating CI '${selectedProject.ci_name}'`,
    };

    // console.log("Final ci request: " + JSON.stringify(ciRequest));
    await runPostRequest(
      ciRequest,
      "/datastore/create/ci",
      "/history",
      false,
      messages
    );
  };

  const sendRequest = (scoreRequest: any) => {
    currentUser.getIdToken(true).then(function (idToken: any) {
      fetch("/datastore/create/score", {
        method: "post",
        headers: { Authorization: idToken, "Content-Type": "application/json" },
        body: JSON.stringify(scoreRequest),
      }).then((response) => response.json());
    });
  };

  const nextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (step === CIStep.CLIENT_SELECTION) {
      return setStep(CIStep.CI_POPULATION);
    }
    if (step === CIStep.CI_POPULATION) {
      if (audienceList.length < 2) {
        console.log("Audience List: " + JSON.stringify(audienceList));
        return enqueueSnackbar(`At least 2 populations must be provided`);
      }
      return setStep(CIStep.SEGMENT_SELECTION);
    }
    if (step === CIStep.SEGMENT_SELECTION) {
      return setStep(CIStep.MAPPING_SELECTION);
    }
    if (step === CIStep.MAPPING_SELECTION) {
      return setStep(CIStep.DASHBOARD_TEMPLATE);
    }
  };

  const prevStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    if (step === CIStep.SEGMENT_SELECTION) {
      return setStep(CIStep.CI_POPULATION);
    }
    if (step === CIStep.MAPPING_SELECTION) {
      return setStep(CIStep.SEGMENT_SELECTION);
    }
    if (step === CIStep.CI_POPULATION) {
      setSelectedClient({
        projects: [],
        client_name: null,
        uuid: null,
        available_data: {},
      });
      return setStep(CIStep.CLIENT_SELECTION);
    }
  };

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={10}>
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <div>
          <br />
          <br />
          {step === CIStep.CLIENT_SELECTION && (
            <CIClientSelection
              nextStep={nextStep}
              prevStep={prevStep}
              selectedClient={selectedClient}
              setSelectedClient={setSelectedClient}
              setSelectedProject={setSelectedProject}
              setRequestCountry={setRequestCountry}
              label="CI Name"
              name_field="ci_name"
            />
          )}

          {step === CIStep.CI_POPULATION && (
            <CIPopulation
              nextStep={nextStep}
              prevStep={prevStep}
              selectedClient={selectedClient}
              selectedProject={selectedProject}
              audienceList={audienceList}
              addPopulation={addPopulation}
              deletePopulation={deletePopulation}
              country={requestCountry}
            />
          )}

          {step === CIStep.SEGMENT_SELECTION && (
            <SegmentSelection
              prevStep={prevStep}
              nextStep={nextStep}
              setSelectedSegments={setSelectedSegments}
              country={requestCountry}
            />
          )}

          {step === CIStep.MAPPING_SELECTION && (
            <Mappings
              nextStep={nextStep}
              prevStep={prevStep}
              createRequest={createRequest}
              country={requestCountry}
              audienceList={audienceList}
              mappingList={mappingList}
              addMapping={addMapping}
              deleteMapping={deleteMapping}
            />
          )}

          {step === CIStep.DASHBOARD_TEMPLATE && (
            <DashboardTemplate
              setTemplateName={setTemplateName}
              setTemplateTheme= {setTemplateTheme}
              prevStep={prevStep}
              createRequest={createRequest}
              country={requestCountry}
              audienceList={audienceList}
              mappingList={mappingList}
              addMapping={addMapping}
              deleteMapping={deleteMapping}
            />
          )}
        </div>
      </Grid>
    </Grid>
  );
}

export default CI;
