import Grid from "@mui/material/Grid";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import * as yup from "yup";
import { useFormik } from "formik";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { SelectTaxos } from "../datafetcher/DatafetcherFeatures";

const segmentSelectionValidationSchema = yup.object({});

function DspTransfer(props: any) {
  const formik = useFormik({
    initialValues: {
      xandr: {weborama_france_9944: true},
      dv360: {Weborama_ES_FR_836373: false, Weborama_P1_DBM_PT_914067: false}
    },
    validationSchema: segmentSelectionValidationSchema,
    onSubmit: async (value: any) => {
      await props.createRequest(value);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={4} alignItems="center" justifyContent="center">
          <Grid item xs={10}>
            <b><p>Xandr</p></b>
            <FormGroup row={true}>
              <FormControlLabel
                control={<Switch defaultChecked />}
                label="WEBORAMA FRANCE (9944)"
                value={formik.values.xandr}
                onChange={formik.handleChange("xandr.weborama_france_9944")}/>
            </FormGroup>
          </Grid>
      </Grid>

      <Grid container spacing={4} alignItems="center" justifyContent="center">
        <Grid item xs={10}>
          <b><p>Display & Video 360</p></b>
          <FormGroup>
            <FormControlLabel
              control={<Switch defaultChecked={false} />}
              label="Weborama ES - FR (836373)"
              value={formik.values.dv_360}
              onChange={formik.handleChange("dv360.Weborama_ES_FR_836373")}
            />
            <FormControlLabel
              control={<Switch defaultChecked={false} />}
              label="Weborama P1 – DBM – PT (914067)"
              value={formik.values.dv_360}
              onChange={formik.handleChange("dv360.Weborama_P1_DBM_PT_914067")}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={10}>
          <Button
            type="submit"
            variant="contained"
            endIcon={<SendIcon />}
            fullWidth
          >
            Create score
          </Button>
          <br />
          <br />
        </Grid>

        <Grid item xs={10}>
          <Button variant="contained" fullWidth onClick={props.prevStep}>
            Back
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default DspTransfer;
