import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import WAMPopulation from "./WAMPopulation";
import WCMPopulation from "./WCMPopulation";
import WFPopulation from "./WFPopulation";
import CustomQueryPopulation from "./CustomQueryPopulation";
import CountryPopulation from "./CountryPopulation";

export enum PopulationType {
  WAM = "WAM",
  WCM = "WCM",
  WF = "WF",
  CQ = "CUSTOM_QUERY",
  CP = "COUNTRY_POPULATION",
}

const countryToLabel: any = {
  FR: "France",
  ES: "Spain",
  PT: "Portugal",
  NL: "Netherlands",
  DE: "Germany",
  GB: "UK",
  RU: "Russia",
  IT: "Italy",
};

const sourceTypeLabel: any = {
  WAM: "Datamining WAM",
  WCM: "Datamining WCM",
  WF: "Wamfactory Logs"
};


export const PopulationSourcePicker = (props: any) => {

  return (
    <Select
      labelId="sourcePicker"
      id="sourcePicker"
      label="Population type"
      onChange={props.handler}
      value={props.value}
      fullWidth
    >
      {/* Only show field if data is available */}
      {Object.entries(props.availableData).map(
        ([key, value]) => value && <MenuItem value={key}>{sourceTypeLabel[key]}</MenuItem>
      )}
      <MenuItem value={PopulationType.CQ}>Beast mode</MenuItem>

      {props.type == "negative" && (
        <MenuItem value={PopulationType.CP}>
          {countryToLabel[props.countryPopulation]}
        </MenuItem>
      )}
    </Select>
  );
};

function Population(props: any) {
  const [populationType, setPopulationType] = React.useState(
    PopulationType.WAM
  );

  const populationTypeHandler = (event: any) => {
    setPopulationType(event.target.value);
  };

  if (populationType === PopulationType.WAM) {
    return (
      <WAMPopulation
        sourceHandler={populationTypeHandler}
        addAudience={props.addAudience}
        selectedClient={props.selectedClient}
        {...props}
      />
    );
  } else if (populationType === PopulationType.WCM) {
    return (
      <WCMPopulation
        sourceHandler={populationTypeHandler}
        addAudience={props.addAudience}
        selectedClient={props.selectedClient}
        {...props}
      />
    );
  } else if (populationType === PopulationType.WF) {
    return (
      <WFPopulation
        sourceHandler={populationTypeHandler}
        addAudience={props.addAudience}
        selectedClient={props.selectedClient}
        {...props}
      />
    );
  } else if (populationType === PopulationType.CQ) {
    return (
      <CustomQueryPopulation
        sourceHandler={populationTypeHandler}
        addAudience={props.addAudience}
        selectedClient={props.selectedClient}
        {...props}
      />
    );
  } else if (populationType === PopulationType.CP && props.type == "negative") {
    return (
      <CountryPopulation
        sourceHandler={populationTypeHandler}
        addAudience={props.addAudience}
        selectedClient={props.selectedClient}
        {...props}
      />
    );
  } else {
    return <div>Population type not implemented yet</div>;
  }
}

export default Population;
