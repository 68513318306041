import * as yup from "yup";
import { useFormik } from "formik";
import { PopulationSourcePicker, BilanType } from "./Bilan";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import {SqlFilter} from "../utils/reusableComponents"
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const regionSelectionValidationSchema = yup.object({
  report_name: yup
    .string()
    .required("The report name is required"),
  report_type: yup
    .string()
    .required("The report type is required"),
  io_id: yup
    .string()
    .required("At least one Insertion Order ID is required")
    .matches(
      /^([0-9]+,)*[0-9]+$/,
      "ID can only contain numbers separated by commas"
    ),
  advertiser_id: yup
    .string()
    .required("One Advertiser ID is required")
    .matches(
      /^([0-9]+)*[0-9]+$/,
      "ID can only contain numbers"
    ),
  budget: yup
    .string()
    .required("The budget is required")
    .matches(
      /^([0-9]+)*[0-9]+$/,
      "Budget can only contain numbers"),
  date_start:yup
    .date()
    .required(),
  date_end:yup
    .date()
    .required(),
});

function RegionReport(props: any) {
    const formik = useFormik({
        initialValues: {
          report_type: '',
          report_name: '',
          advertiser_id: '',
          io_id: '',
          budget: '',
          date_start: '',
          date_end: '',
        },
        validationSchema: regionSelectionValidationSchema,

        onSubmit: async (value: any) => {
          await props.createRequest(value);
        },
      });

  return (
    <form onSubmit={formik.handleSubmit}>        
        <Grid container spacing={1} alignItems="center" justifyContent="center">
            <Grid item xs={12}>
                <PopulationSourcePicker
                        handler={props.sourceHandler}
                        value={BilanType.REGION}
                        availableData=""
                        countryPopulation=""
                        {...props}
                />
            </Grid>

            <Grid item xs={10}>
                <hr
                style={{
                background: "#A5A4A4",
                height: "1px",
                width:"100%",
                border: "none",
                }}
                />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="wcm_action"
                select
                label="Report type"
                value={formik.values.report_type}
                onChange={formik.handleChange("report_type")}
                helperText="Select a report type"
                fullWidth
              >
                <MenuItem value={"TheGoodWeb"}>The GoodWeb</MenuItem>
                <MenuItem value={"3BMedias"}>3B Medias</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12}>
                <Tooltip title="Choose your report name ex: Tarification solidaire de l'eau">
                    <TextField
                        id="report_name"
                        name="report_name"
                        label="Report name"
                        value={formik.values.report_name}
                        onChange={formik.handleChange("report_name")}
                        error={formik.touched.report_name && Boolean(formik.errors.report_name)}
                        helperText={formik.touched.report_name && formik.errors.report_name}
                        fullWidth
                    />
                </Tooltip>
            </Grid>

            <Grid item xs={20}>
            </Grid>
            <Grid item xs={12}>
            </Grid>
            
            <Grid item xs={12}>
                <Tooltip title='Choose one Advertiser ID ex: 7196495'>
                    <TextField
                        id="advertiser_id"
                        name="advertiser_id"
                        label="Advertiser ID"
                        value={formik.values.advertiser_id}
                        onChange={formik.handleChange("advertiser_id")}
                        error={formik.touched.advertiser_id && Boolean(formik.errors.advertiser_id)}
                        helperText={formik.touched.advertiser_id && formik.errors.advertiser_id}
                        fullWidth
                    />
                </Tooltip>
            </Grid>

            <Grid item xs={12}>
                <Tooltip title='Choose one or more Insertion Order ID separated with an "," ex: 8003940, 8003941'>
                    <TextField
                        id="io_id"
                        name="io_id"
                        label="Insertion Order ID"
                        value={formik.values.io_id}
                        onChange={formik.handleChange("io_id")}
                        error={formik.touched.io_id && Boolean(formik.errors.io_id)}
                        helperText={formik.touched.io_id && formik.errors.io_id}
                        fullWidth
                    />
                </Tooltip>
            </Grid>

            <Grid item xs={12}>
                <Tooltip title='Budget of your campain ex: 1314.517'>
                    <TextField
                        id="budget"
                        name="budget"
                        label="Budget"
                        value={formik.values.budget}
                        onChange={formik.handleChange("budget")}
                        error={formik.touched.budget && Boolean(formik.errors.budget)}
                        helperText={formik.touched.budget && formik.errors.budget}
                        fullWidth
                    />
                </Tooltip>
            </Grid>

            <Grid item xs={10}>
            </Grid>
            <Grid item xs={12}>
            </Grid>

            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Date start"
                  inputFormat="dd/MM/yyyy"
                  value={formik.values.date_start}
                  onChange={(value: any): void => {
                    formik.setFieldValue("date_start", value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={
                        formik.touched.date_start && formik.errors.date_start
                      }
                      error={
                        formik.touched.date_start &&
                        Boolean(formik.errors.date_start)
                      }
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Date end"
                  inputFormat="dd/MM/yyyy"
                  value={formik.values.date_end}
                  onChange={(value: any): void => {
                    formik.setFieldValue("date_end", value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={
                        formik.touched.date_end && formik.errors.date_end
                      }
                      error={
                        formik.touched.date_end &&
                        Boolean(formik.errors.date_end)
                      }
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={10}>
              <Button
                type="submit"
                variant="contained"
                endIcon={<SendIcon />}
                fullWidth
              >
                CREATE REPORT
              </Button>
              <br />
              <br />
            </Grid>
        </Grid>
    </form>
  );
}

export default RegionReport;
