import * as yup from "yup";
import { useFormik } from "formik";
import { PopulationType, PopulationSourcePicker } from "./CI";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { SqlFilter, PopulationName } from "../utils/reusableComponents";
import {
  populationNameValidator,
  startValueValidator,
  endValueValidator,
} from "../validators";

const wcmPopulationValidationSchema = yup.object({
  population_name: populationNameValidator,
  date_start: startValueValidator,
  date_end: endValueValidator,
});

function WCMPopulation(props: any) {
  const formik = useFormik({
    initialValues: {
      sql_condition: "",
      wcm_action: "impressionvisibility",
      wcm_id: "",
      population_name: "",
      date_start: null,
      date_end: null,
    },
    validationSchema: wcmPopulationValidationSchema,
    onSubmit: (value: any) => {
      value.pop_type = "WCM";
      console.log("Calling addPopulation() : " + JSON.stringify(value));
      props.addPopulation(value);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={4} alignItems="center" justifyContent="center">
        <Grid item xs={10}>
          <Grid
            container
            spacing={4}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12}>
              <PopulationName
                value={formik.values.population_name}
                onChange={formik.handleChange("population_name")}
                error={
                  formik.touched.population_name &&
                  Boolean(formik.errors.population_name)
                }
                helperText={
                  formik.touched.population_name &&
                  formik.errors.population_name
                }
              />
            </Grid>

            <Grid item xs={12}>
              <PopulationSourcePicker
                handler={props.sourceHandler}
                value={PopulationType.WCM}
                availableData={props.selectedClient.available_data}
                countryPopulation={props.selectedProject.country}
                {...props}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="wcm_action"
                select
                label="Action WCM"
                value={formik.values.wcm_action}
                onChange={formik.handleChange("wcm_action")}
                helperText="Select a WCM action"
                fullWidth
              >
                <MenuItem value={"impression"}>Impression</MenuItem>
                <MenuItem value={"click"}>Click</MenuItem>
                <MenuItem value={"conversion"}>Conversion</MenuItem>
              </TextField>
            </Grid>
{/* 
            <h4 style={{color: 'red'}}>WARNING: For WCM conversion request, write 291,291.0 for conversion tag 291 (example)</h4> */}
            {formik.values.wcm_action == "conversion" && (
              <Grid item xs={12}>
                <Tooltip title='Choose one or more conversion Tag ID separated with an "," ex: 12,2,35,4'>
                  <TextField
                    id="wcm_id"
                    name="wcm_id"
                    label="Conversion ID"
                    value={formik.values.wcm_id}
                    onChange={formik.handleChange("wcm_id")}
                    error={
                      formik.touched.wcm_id && Boolean(formik.errors.wcm_id)
                    }
                    helperText={formik.touched.wcm_id && formik.errors.wcm_id}
                    fullWidth
                  />
                </Tooltip>
              </Grid>
            )}

            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Date start"
                  inputFormat="dd/MM/yyyy"
                  value={formik.values.date_start}
                  onChange={(value: any): void => {
                    formik.setFieldValue("date_start", value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={
                        formik.touched.date_start && formik.errors.date_start
                      }
                      error={
                        formik.touched.date_start &&
                        Boolean(formik.errors.date_start)
                      }
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Date end"
                  inputFormat="dd/MM/yyyy"
                  value={formik.values.date_end}
                  onChange={(value: any): void => {
                    formik.setFieldValue("date_end", value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={
                        formik.touched.date_end && formik.errors.date_end
                      }
                      error={
                        formik.touched.date_end &&
                        Boolean(formik.errors.date_end)
                      }
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <SqlFilter
                title='More specific filter for your audience (ex: techno like "%Win10%") or intersection with one or more WAM audiences(ex: audiences like "%123456%")'
                id="sql_condition"
                name="sql_condition"
                label="SQL Filter (optional)"
                value={formik.values.sql_condition}
                onChange={formik.handleChange("sql_condition")}
                error={
                  formik.touched.sql_condition &&
                  Boolean(formik.errors.sql_condition)
                }
                helperText={
                  formik.touched.sql_condition && formik.errors.sql_condition
                }
              ></SqlFilter>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={2}>
          <Button type="submit" variant="contained" endIcon={<SendIcon />}>
            Add
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default WCMPopulation;
