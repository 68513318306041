import * as yup from "yup";

export const audienceNameValidator = yup
  .string()
  .required("A name is required for the audience")
  .min(3, "Audience name must be at least 3 characters")
  .matches(
    /^[a-zA-Z0-9]*$/,
    "Only letters and numbers are allowed in audience name."
  );

export const populationNameValidator = yup
  .string()
  .required("A name is required for the population")
  .min(3, "Audience name must be at least 3 characters")
  .matches(
    /^[A-Z][\u00E0-\u00FCa-zA-Z0-9 _]*$/,
    "Only letters, numbers and underscores are allowed in the population name. First letter must be a capital."
  );

export const titleDashTabValidator = yup
  .string()
  .required("A title is required")
  .min(3, "Title must be at least 3 characters long")
  .matches(
    /^[\u00E0-\u00FCa-zA-Z0-9 _]*$/,
    "Only letters, numbers and underscores are allowed in the title."
  );


export function letterNumberValidator(field_name: any, min_size: any) {
  return yup
    .string()
    .required(`'${field_name}' is required`)
    .min(min_size, `Field '${field_name}' must be at least ${min_size} characters long`)
    .matches(/^[a-zA-Z0-9]*$/, "Only letters and numbers are allowed");
}

export function onlyNumber(field_name: any, min_size: any) {
  return yup
    .string()
    .min(min_size, `Field '${field_name}' must be at least ${min_size} characters long`)
    .matches(/^[0-9]*$/, "Only numbers are allowed");
}

export function letterNumberSpaceValidator(field_name: any, min_size: any) {
  return yup
    .string()
    .required(`'${field_name}' is required`)
    .min(min_size, `Field '${field_name}' must be at least ${min_size} characters long`)
    .matches(/^[a-zA-Z0-9 ]*$/, "Only letters, numbers and spaces are allowed");
}

export function letterNumberUnderscoreDashValidator(field_name: any, min_size: any) {
  return yup
    .string()
    .required(`'${field_name}' is required`)
    .min(min_size, `Field '${field_name}' must be at least ${min_size} characters long`)
    .matches(/^[a-zA-Z0-9_\- ]*$/, "Only letters, numbers and spaces are allowed");
}

export const clientNameValidator = yup
  .string()
  .required("A client name is required")
  .min(3, "Client name must be at least 3 characters long")
  .matches(
    /^[a-zA-Z0-9]*$/,
    "Only letters and numbers are allowed in the client's name"
  );

export const startValueValidator = yup
  .date()
  .typeError("A start date is required")
  .required("A start date is required")
  .max(yup.ref("date_end"), "Start date must be before end date");

export const endValueValidator = yup
  .date()
  .typeError("An end date is required")
  .required("An end date is required")
  .min(yup.ref("date_start"), "End date must be after start date");

export const subsampleValidator = yup
  .string()
  .required("Subsample is required")
  .min(1, "Subsample is required")
  .matches(/^[0-9]*$/, "Subsample must be a number");

export const minUUValidator = yup
  .string()
  .required("Min uu is required")
  .min(1, "Min uu is required")
  .matches(/^[0-9]*$/, "Min uu must be a number");

export const delayValidator = yup
  .string()
  .required("Delay is required")
  .min(1, "Delay is required")
  .matches(/^[0-9]*$/, "Delay must be a number");

export const projectValidator = yup
  .string()
  .required("Project is required")
  .min(1, "Project is required");

export const datasetValidator = yup
  .string()
  .required("A dataset is required for the audience")
  .min(1, "A dataset is required for the audience");

export const tableNameValidator = yup
  .string()
  .required("Table name is required")
  .min(3, "WCM id must contain at least 3 characters")
  .matches(
    /^[a-zA-Z0-9_]*$/,
    "Only letters and numbers are allowed in the table name"
  );

export const scoreNameValidator = yup
  .string()
  .required("A score name is required")
  .min(5, "Score name must be at least 5 characters long")
  .matches(
    /^[a-zA-Z0-9_]*$/,
    "Only letters, numbers and underscores are allowed in the score name"
  );


export const projectNameValidator = yup
  .string()
  .required("A project is required")
  .min(5, "Project name must be at least 5 characters long")
  .matches(
    /^[a-zA-Z0-9_]*$/,
    "Only letters, numbers and underscores are allowed in the project name"
  );


export const sqlQueryValidator = yup
  .string()
  .required("An SQL query is required")
  .min(3, "Sql query");

export const optionalSqlQueryValidator = yup.string().min(3, "Sql query");

export const wcmIdValidator = yup
  .string()
  .min(2, "WCM id must contain at least 2 characters");
