import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { letterNumberValidator, letterNumberSpaceValidator, titleDashTabValidator } from "../validators";
import Tooltip from '@mui/material/Tooltip';

const embedInputValidationSchema = yup.object({
  token: letterNumberValidator("Embed token", 3),
  label: titleDashTabValidator,
});

const EmbedInput = (props: any) => {
  const formik = useFormik({
    initialValues: {
      token: "",
      label: "",
    },
    validationSchema: embedInputValidationSchema,
    onSubmit: (value: any) => {
      props.addEmbed(value);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={4} alignItems="center" justifyContent="center">
        <Grid item xs={10}>
          <Grid
            container
            spacing={4}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12}>
            <Tooltip title="Embed token of the page that will be displayed. To find it in Domo, go into 'Embed Dashboard' on the page you want to add then set 'Private' in 'Embed options' and get the token at the end of the generated 'Share link' " arrow>
              <TextField
                id="token"
                name="token"
                label="Embed token"
                fullWidth
                value={formik.values.token}
                onChange={formik.handleChange}
                error={formik.touched.token && Boolean(formik.errors.token)}
                helperText={formik.touched.token && formik.errors.token}
              />
              </Tooltip>
            </Grid>

            <Grid item xs={12}>
            <Tooltip title="Title that will be displayed in the tabs used to switch between pages" arrow>
              <TextField
                id="label"
                name="label"
                label="Title"
                fullWidth
                value={formik.values.label}
                onChange={formik.handleChange}
                error={formik.touched.label && Boolean(formik.errors.label)}
                helperText={formik.touched.label && formik.errors.label}
              />
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={2}>
          <Button type="submit" variant="contained" endIcon={<SendIcon />}>
            Add
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export { EmbedInput };
