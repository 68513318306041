import Grid from "@mui/material/Grid";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import {
  SelectClient,
  SelectCountry,
  SelectProject,
  CreateProject,
} from "../utils/reusableComponents";
import * as yup from "yup";
import { scoreNameValidator, projectNameValidator } from "../validators";
import IconButton from "@mui/material/IconButton";
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";

function ScoreClientSelection(props: any) {
  // TODO: client_name validation

  const navigate = useNavigate();

  const validationSchema = yup.object({
    country: yup.string().required("A country is required"),
    score_name: scoreNameValidator,
    project_name: projectNameValidator
  });
  

  const formik = useFormik({
    initialValues: {
      country: "FR",
      client_name: props.selectedClient.client_name,
      project_name: "",
      score_name: "",
    },

    validationSchema: validationSchema,
    onSubmit: (value:any) => {
      if(props.name_field == "ci_name"){
        value.ci_name = value.score_name
        delete value.score_name
      }

      value.client_name = props.selectedClient.client_name;
      props.setSelectedProject(value);
      props.setRequestCountry(value.country);
      props.nextStep();
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          direction="row"
        >
          <Grid item xs={10}>
            <SelectCountry
              value={formik.values.country}
              onChange={formik.handleChange("country")}
              error={formik.touched.country && Boolean(formik.errors.country)}
              helperText={formik.touched.country && formik.errors.country}
            />
          </Grid>
          <Grid item xs={10}>
            <Tooltip title="Create a new client. You will receive an email once the data for the new client is retrieved.">
              <IconButton
                color="primary"
                aria-label="create new client"
                onClick={async () => navigate("/new_client")}
              >
                <AddCircleIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={10}>
            <SelectClient
              setSelectedClient={props.setSelectedClient}
              selectedClient={props.selectedClient}
              multiple={false}
              error={
                formik.touched.client_name && Boolean(formik.errors.client_name)
              }
              helperText={
                formik.touched.client_name && formik.errors.client_name
              }
              country={formik.values.country}
            />
          </Grid>

          <Grid item xs={10}>
            <CreateProject
              client_uuid={props.selectedClient.uuid}
              setSelectedClient={props.setSelectedClient}
            />
          </Grid>

          <Grid item xs={10}>
            <SelectProject
              value={formik.values.project_name}
              onChange={formik.handleChange("project_name")}
              projectList={props.selectedClient.projects}
              error={
                formik.touched.project_name &&
                Boolean(formik.errors.project_name)
              }
              helperText={
                formik.touched.project_name && formik.errors.project_name
              }
            />
          </Grid>

          <Grid item xs={10}>
            <TextField
              id={props.name_field ? props.name_field : "score_name"}
              name={props.name_field ? props.name_field : "score_name"}
              label={props.label ? props.label : "Score name"}
              value={formik.values.score_name}
              onChange={formik.handleChange("score_name")}
              error={
                formik.touched.score_name && Boolean(formik.errors.score_name)
              }
              helperText={formik.touched.score_name && formik.errors.score_name}
              fullWidth
            />
          </Grid>

          <Grid item xs={10}>
            <Button
              type="submit"
              variant="contained"
              endIcon={<SendIcon />}
              fullWidth
            >
              Next
            </Button>
            <br />
            <br />
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default ScoreClientSelection;
