import React, { useState, useEffect } from "react";

import { useAuth } from "../../contexts/AuthContext";

import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

const EmbedList = (props: any) => {
  return (
    <Paper elevation={3}>
      <List dense={true}>
        {props.embedList.map((embed: any) => (
          <EmbedItem
            deleteEmbed={props.deleteEmbed}
            embed={embed}
          />
        ))}
      </List>
    </Paper>
  );
};

const EmbedItem = (props: any) => {
  return (
    <ListItem
      secondaryAction={
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => props.deleteEmbed(props.embed.uuid)}
        >
          <DeleteIcon />
        </IconButton>
      }
    >
      <ListItemText
        primary={props.embed.label}
        secondary={props.embed.token}
      />
      {/* TODO: fix secondary text here (not displayed) */}
    </ListItem>
  );
};

export default EmbedList;
