import { useTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import Stack from "@mui/material/Stack";

function Homepage() {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="upper"
      justifyContent="center"
    >
      <Stack spacing={2}>
      
        <Card sx={{ minWidth: 500, minHeight: 267, border: '3px solid #071832'}}>
          <CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div" color="#071832" style={{ fontSize: '30px' }}>
                Activation
              </Typography>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img src="/static/lookalike_cookie.png" alt="lookalike_cookie" style={{ marginLeft: '10px', width: '40px', height: '40px' }} />
                  <a href="/score" style={{ fontSize: '20px', marginLeft: '15px' }}>
                    Cookies lookalike
                  </a>
                </div>

                <br />

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img src="/static/lookalike_url.png" alt="lookalike_url" style={{ marginRight: '10px', width: '55px', height: '55px' }} />
                  <a href="/score_url" style={{ fontSize: '20px' }}>
                    URLs lookalike
                  </a>
                </div>

                <br />

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img src="/static/url_extension.png" alt="url_extension" style={{ marginRight: '10px', width: '55px', height: '55px' }} />
                  <a style={{ fontSize: '20px' }}>
                    URLs extension (coming soon)
                  </a>
                </div>

            </CardContent>
          </CardActionArea>
        </Card>

      <a>To add a new client: <a href="/new_client"> Click here </a></a>
      <a>To embed the dashboard into an external link: <a href="/create_dashboard"> Click here </a></a>
        
      </Stack>

      <Stack spacing={2}>
      
        <Card sx={{ minWidth:  500, border: '3px solid #071832'}}>
          <CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div" color="#071832" style={{ fontSize: '30px' }}>
                Insights
              </Typography>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img src="/static/insights.png" alt="insights" style={{ marginRight: '10px', width: '40px', height: '40px' }} />
                  <a href="/ci" style={{ fontSize: '20px' }}>
                    Consumer Insights / Post-campaign Dashboard
                  </a>
                </div>

            </CardContent>
          </CardActionArea>
        </Card>

        <Card sx={{ minWidth:  500, border: '3px solid #071832'}}>
          <CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div" color="#071832" style={{ fontSize: '30px' }}>
                Reporting
              </Typography>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img src="/static/reports.png" alt="reports" style={{ marginRight: '10px', width: '40px', height: '40px' }} />
                  <a href="/activationreport" style={{ fontSize: '20px' }}>
                    Post-campaign reports
                  </a>
                </div>

            </CardContent>
          </CardActionArea>
        </Card>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src="/static/sablier.png" alt="reports" style={{ marginRight: '10px', width: '40px', height: '40px' }} />
            <a href="/history" style={{ fontSize: '20px' }}>
              Request History
            </a>
        </div>
        
      </Stack>

    </Stack>
  );
}

export default Homepage;
