import React from "react";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import * as yup from "yup";
import { PopulationSourcePicker, PopulationType } from "./Population";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import { sqlQueryValidator } from "../validators";

const countryPopulationValidationSchema = yup.object({
});

function CountryPopulation(props: any) {
  const formik = useFormik({
    initialValues: {},
    validationSchema: countryPopulationValidationSchema,
    onSubmit: (value: any) => {
      value.pop_type = "CP";
      value.country = props.selectedProject.country
      props.after(value);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={4} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <PopulationSourcePicker
            handler={props.sourceHandler}
            value={PopulationType.CP}
            availableData={props.selectedClient.available_data}
            countryPopulation={props.selectedProject.country}
            {...props}
          />
        </Grid>

        <Grid item xs={10}>
          <Button
            type="submit"
            variant="contained"
            endIcon={<SendIcon />}
            fullWidth
          >
            Next
          </Button>
          <br />
          <br />
        </Grid>

        <Grid item xs={10}>
          <Button variant="contained" fullWidth onClick={props.prevStep}>
            Back
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default CountryPopulation;
