import { useState, useEffect, forwardRef } from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import UpdateIcon from "@mui/icons-material/Update";
import Link from "@mui/material/Link";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { useSnackbar } from "notistack";
import StopIcon from "@mui/icons-material/Stop";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import ErrorIcon from "@mui/icons-material/Error";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import LoopIcon from "@mui/icons-material/Loop";
import Tooltip from "@mui/material/Tooltip";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import InsightsSharpIcon from '@mui/icons-material/InsightsSharp';


const STATE_SHOW_TRAIN_BUTTON = [
  "SC_TRAIN_DONE",
  "LAL_URL_TRAIN_DONE",
  "LAL_URL_PROJECTION_RUNNING",
  "LAL_URL_PROJECTION_PAUSED",
];
const STATE_SHOW_UPDATE_BUTTON = [];
const STATE_SHOW_PROJECTION_BUTTON = [];

const ScoreProjectionButton = (props: any) => {
  const { currentUser } = useAuth();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  var is_paused = true;
  if (props.state == "LAL_URL_PROJECTION_PAUSED") {
    is_paused = false;
  }

  const sendRequestUpdateScore = (uuid: any, callback: any) => {
    currentUser.getIdToken(true).then(function (idToken: any) {
      fetch("/datastore/change_url_projection_state", {
        method: "post",
        headers: { Authorization: idToken, "Content-Type": "application/json" },
        body: JSON.stringify({ uuid: uuid, is_paused: is_paused }),
      })
        .then((response) => response.json())
        .then((responseData) => {
          callback(responseData);
        });
    });
  };

  function afterCall(response: any) {
    enqueueSnackbar(response.message);
    props.setNeedReload(!props.needReload);
  }

  const handleConfirmation = () => {
    // call backend to update state of the request
    sendRequestUpdateScore(props.uuid, afterCall);
    setOpen(false);
  };

  return (
    <div>
      <IconButton
        onClick={handleClickOpen}
        disabled={
          !(
            "airflow_info" in props.params.metadata &&
            (props.params.metadata.state == "LAL_URL_PROJECTION_RUNNING" ||
              props.params.metadata.state == "LAL_URL_PROJECTION_PAUSED")
          )
        }
      >
        {props.params.metadata.state == "LAL_URL_PROJECTION_PAUSED" && (
          <div>
            {/* <p>cc</p> */}
            <PlayArrowIcon />
          </div>
        )}
        {props.params.metadata.state == "LAL_URL_PROJECTION_RUNNING" && (
        <div>
          {/* <div> */}
            {/* <img src = "/static/xandr_logo.png" width="25" height="30"></img> */}
            {/* <img src = "/static/dv360_logo.png" width="45" height="45"></img> */}
          {/* </div> */}
          <StopIcon />
        </div>)}
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.params.metadata.state == "LAL_URL_PROJECTION_PAUSED" &&
            "Start projection ?"}
          {props.params.metadata.state == "LAL_URL_PROJECTION_RUNNING" &&
            "Stop projection ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.params.metadata.state == "LAL_URL_PROJECTION_PAUSED" && ""}
            {props.params.metadata.state == "LAL_URL_PROJECTION_RUNNING" && ""}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirmation} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const renderStateIcon = (params: any) => {
  return (
    <>
      {params.row.metadata.state == "LAL_URL_NEW_REQUEST" && (
        <Tooltip title="This score request is currently being processed">
          <LoopIcon />
        </Tooltip>
      )}
      {params.row.metadata.state == "LAL_URL_WAITING_DF" && (
        <Tooltip title="This score request is currently being processed">
          <LoopIcon />
        </Tooltip>
      )}
      {params.row.metadata.state == "LAL_URL_RUNNING" && (
        <Tooltip title="Training is currently running">
          <HourglassTopIcon />
        </Tooltip>
      )}
      {params.row.metadata.state == "LAL_URL_TRAIN_DONE" && (
        <Tooltip title="This score request is currently being processed">
          <LoopIcon />
        </Tooltip>
      )}
      {params.row.metadata.state == "LAL_URL_DF_DONE" && (
        <Tooltip title="This score request is currently being processed">
          <LoopIcon />
        </Tooltip>
      )}
      {params.row.metadata.state == "LAL_URL_DONE" && (
        <Tooltip title="This score request is currently being processed">
          <LoopIcon />
        </Tooltip>
      )}

      {params.row.metadata.state == "LAL_URL_PROJECTION_RUNNING" && (
        <Tooltip title="Data is currently being sent to WAM every day">
          <PublishedWithChangesIcon />
        </Tooltip>
      )}
      {params.row.metadata.state == "LAL_URL_PROJECTION_PAUSED" && (
        <Tooltip title="No data is currently being sent to WAM">
          <PauseCircleIcon />
        </Tooltip>
      )}

      {params.row.metadata.state == "LAL_URL_DF_ERROR" && (
        <Tooltip title="An error occured">
          <ErrorIcon />
        </Tooltip>
      )}
      {params.row.metadata.state == "LAL_URL_JOB_ERROR" && (
        <Tooltip title="An error occured">
          <ErrorIcon />
        </Tooltip>
      )}
      {params.row.metadata.state == "LAL_URL_PROJECTION_ERROR" && (
        <Tooltip title="An error occured">
          <ErrorIcon />
        </Tooltip>
      )}
      {params.row.metadata.state == "LAL_URL_FORMAT_ERROR" && (
        <Tooltip title="An error occured">
          <ErrorIcon />
        </Tooltip>
      )}
    </>
  );
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function RenderTrainingLink(props: any) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton
        onClick={handleClickOpen}
        disabled={
          !STATE_SHOW_TRAIN_BUTTON.includes(props.params.metadata.state)
        }
      >
        <AnalyticsIcon />
      </IconButton>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Score performance
            </Typography>
          </Toolbar>
        </AppBar>
        <iframe
          name={`recap-page`}
          frameBorder="0"
          src={`/score_url/training_recap/${props.uuid}`}
          style={{
            display: "block",
            background: "#1F3B50",
            height: "calc(100vh - 60px)",
            width: "calc(100vw - 0px)",
            margin: "auto",
            border: "none",
          }}
        ></iframe>
      </Dialog>
    </div>
  );
}

const renderDashIcon = (params: any) => {
  return (
    <>
      {
        "dash_url" in params.row.metadata && (
          <Link href={params.row.metadata.dash_url} target="_blank">
            <Tooltip title="Dashboard">
              <IconButton>
                <InsightsSharpIcon />
              </IconButton>
            </Tooltip>
          </Link>
        )}
      { !("dash_url" in params.row.metadata) && (
        <Link href={params.row.metadata.dash_url} target="_blank">
          <Tooltip title="Dashboard not available">
            <IconButton disabled={true}>
              <InsightsSharpIcon />
            </IconButton>
          </Tooltip>
        </Link>
      )}
    </>
  );
};

function ScoreUrlHistory(props: any) {
  const { currentUser } = useAuth();
  const [requestList, setRequestList] = useState([]);
  const [historyLoading, setHistoryLoading] = useState(true);
  const [needReload, setNeedReload] = useState(true);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Request ID",
      editable: false,
      align: "center",
      headerAlign: "center",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.metadata.uuid}`,
      flex: 1,
      width: 50,
    },
    {
      field: "country",
      headerName: "Country",
      editable: false,
      flex: 0.2,
      align: "center",
      headerAlign: "center",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.metadata.client.country}`,
    },

    {
      field: "client",
      headerName: "Client",
      editable: false,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.metadata.client.client_name}`,
    },

    {
      field: "project",
      headerName: "Project",
      editable: false,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.metadata.client.project_name}`,
    },

    {
      field: "score_name",
      headerName: "Score name",
      editable: false,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.metadata.client.score_name}`,
    },

    {
      field: "creation_date",
      headerName: "Creation time",
      editable: false,
      flex: 0.7,
      align: "center",
      headerAlign: "center",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.metadata.creation_date}`,
    },
    {
      field: "email",
      headerName: "Owner",
      editable: false,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.metadata.user.email}`,
    },

    {
      field: "state",
      headerName: "Status",
      editable: false,
      flex: 0.2,
      align: "center",
      headerAlign: "center",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.metadata.state}`,
      renderCell: renderStateIcon,
    },
    {
      field: "dash_url",
      headerName: "Dashboard",
      editable: false,
      flex: 0.2,
      align: "center",
      headerAlign: "center",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.metadata.dash_url}`,
      renderCell: renderDashIcon,
    },
    {
      field: "perf",
      headerName: "Performance",
      editable: false,
      align: "center",
      headerAlign: "center",
      flex: 0.5,
      sortable: false,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <RenderTrainingLink
            uuid={params.row.metadata.uuid}
            params={params.row}
          />
        );
      },
    },
    {
      field: "Projection",
      headerName: "Projection",
      editable: false,
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <ScoreProjectionButton
            uuid={params.row.metadata.uuid}
            params={params.row}
            state={params.row.metadata.state}
            needReload={needReload}
            setNeedReload={setNeedReload}
          />
        );
      },
    },

  ];

  useEffect(() => {
    const initialize = () => {
      // get access token to auth to backend then fetch (with token in header?)
      currentUser.getIdToken(true).then(function (idToken: any) {
        fetch("/datastore/get/requests/all/score_url", {
          method: "post",
          headers: {
            Authorization: idToken,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((responseData) => {
            setRequestList(responseData.requests);
            setHistoryLoading(false);
          });
      });
    };
    return initialize();
  }, [needReload]);

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={10}>
        {!historyLoading && (
          <div style={{ height: 800, width: "100%" }}>
            <DataGrid
              rowHeight={75}
              rows={requestList}
              columns={columns}
              pageSize={30}
              disableSelectionOnClick
              getRowId={(row) => row.metadata.uuid}
              initialState={{
                sorting: {
                  sortModel: [{ field: "creation_date", sort: "desc" }],
                },
              }}
            />
          </div>
        )}

        {historyLoading && (
          <Grid item xs={10} alignContent="center">
            <CircularProgress />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default ScoreUrlHistory;
