import * as yup from "yup";
import { useFormik } from "formik";
import { PopulationType, PopulationSourcePicker } from "./CI";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { SqlFilter, PopulationName } from "../utils/reusableComponents";
import {
  populationNameValidator,
  startValueValidator,
  endValueValidator,
} from "../validators";

const wamPopulationValidationSchema = yup.object({
  id: yup
    .string()
    .required("An id is required for the audience")
    .min(1, "An id is required for the audience")
    .matches(/^[a-zA-Z0-9]*$/, "ID can only contains letters and numbers"),
  population_name: populationNameValidator,
  date_start: startValueValidator,
  date_end: endValueValidator,
});

function WAMPopulation(props: any) {
  const formik = useFormik({
    initialValues: {
      id: "",
      sql_condition: "",
      id_type: "custom_segments",
      population_name: "",
      date_start: null,
      date_end: null,
    },
    validationSchema: wamPopulationValidationSchema,
    onSubmit: (value: any) => {
      value.pop_type = "WAM";
      console.log("Calling addPopulation() : " + JSON.stringify(value));
      props.addPopulation(value);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={4} alignItems="center" justifyContent="center">
        <Grid item xs={10}>
          <Grid
            container
            spacing={4}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12}>
              <PopulationName
                value={formik.values.population_name}
                onChange={formik.handleChange("population_name")}
                error={
                  formik.touched.population_name &&
                  Boolean(formik.errors.population_name)
                }
                helperText={
                  formik.touched.population_name &&
                  formik.errors.population_name
                }
              />
            </Grid>

            <Grid item xs={12}>
              <PopulationSourcePicker
                handler={props.sourceHandler}
                value={PopulationType.WAM}
                availableData={props.selectedClient.available_data}
                countryPopulation={props.selectedProject.country}
                {...props}
              />
            </Grid>

            <Grid item xs={12}>
              <Tooltip title="Type of WAM audience">
                <TextField
                  id="id_type"
                  select
                  label="ID Type"
                  value={formik.values.id_type}
                  onChange={formik.handleChange("id_type")}
                  helperText="Select an id Type"
                  fullWidth
                >
                  <MenuItem value={"custom_segments"}>Custom Segment</MenuItem>
                  <MenuItem value={"audiences"}>Audience</MenuItem>
                </TextField>
              </Tooltip>
            </Grid>

            <Grid item xs={12}>
              <Tooltip title="ID of your audience, ex : 223452">
                <TextField
                  id="id"
                  name="id"
                  label="ID"
                  value={formik.values.id}
                  onChange={formik.handleChange("id")}
                  error={formik.touched.id && Boolean(formik.errors.id)}
                  helperText={formik.touched.id && formik.errors.id}
                  fullWidth
                />
              </Tooltip>
            </Grid>

            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Date start"
                  inputFormat="dd/MM/yyyy"
                  value={formik.values.date_start}
                  onChange={(value: any): void => {
                    formik.setFieldValue("date_start", value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={
                        formik.touched.date_start && formik.errors.date_start
                      }
                      error={
                        formik.touched.date_start &&
                        Boolean(formik.errors.date_start)
                      }
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Date end"
                  inputFormat="dd/MM/yyyy"
                  value={formik.values.date_end}
                  onChange={(value: any): void => {
                    formik.setFieldValue("date_end", value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={
                        formik.touched.date_end && formik.errors.date_end
                      }
                      error={
                        formik.touched.date_end &&
                        Boolean(formik.errors.date_end)
                      }
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <SqlFilter
                title='More specific filter for your audience (ex: techno like "%Win10%") or intersection with one or more WAM audiences(ex: audiences like "%123456%")'
                id="sql_condition"
                name="sql_condition"
                label="SQL Filter (optional)"
                value={formik.values.sql_condition}
                onChange={formik.handleChange("sql_condition")}
                error={
                  formik.touched.sql_condition &&
                  Boolean(formik.errors.sql_condition)
                }
                helperText={
                  formik.touched.sql_condition && formik.errors.sql_condition
                }
              ></SqlFilter>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={2}>
          <Button type="submit" variant="contained" endIcon={<SendIcon />}>
            Add
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default WAMPopulation;
