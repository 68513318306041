import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import RegionReport from "./RegionReport";
import BigReport from "./BigReport";
import WCMPopulation from "./WCMPopulation";
import { useState, useEffect } from "react";
// import WFPopulation from "./WFPopulation";
// import CustomQueryPopulation from "./CustomQueryPopulation";
// import CountryPopulation from "./CountryPopulation";

export enum PopulationType {
  WAM = "WAM",
  WCM = "WCM",
  WF = "WF",
  CQ = "CUSTOM_QUERY",
  CP = "COUNTRY_POPULATION",
}

export enum BilanType {
  REGION = "REGION",
  GLOBAL = "GLOBAL",
}

export const PopulationSourcePicker = (props: any) => {

  return (
    <Select
      labelId="sourcePicker"
      id="sourcePicker"
      label="Population type"
      onChange={props.handler}
      value={props.value}
      fullWidth
    >
        <MenuItem selected={true} value={"REGION"}>Regional Report</MenuItem>
        <MenuItem value={"GLOBAL"}>Global Report</MenuItem>
    </Select>
  );
};

function Population(props: any) {
  // const [requestReportName, setRequestReportName] = useState("")
  // const [requestAdvId, setRequestAdvId] = useState("test")
  // const [requestIoId, setRequestIoId] = useState("")

  const [populationType, setPopulationType] = React.useState(
    BilanType.REGION
  );

  const populationTypeHandler = (event: any) => {
    setPopulationType(event.target.value);
  };

  // const populationType = props.type

  if (populationType === BilanType.REGION) {
    return (
      <RegionReport
        sourceHandler={populationTypeHandler}
        addAudience={props.addAudience}
        selectedClient={props.selectedClient}
        createRequest={props.createRequest}
        {...props}
      />
    );
  } else if (populationType === BilanType.GLOBAL) {
    return (
      <BigReport
        sourceHandler={populationTypeHandler}
        addAudience={props.addAudience}
        selectedClient={props.selectedClient}
        {...props}
      />
    );
  } else {
    return <div>Report type not implemented yet</div>;
  }
}

export default Population;
