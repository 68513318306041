// @ts-nocheck
import React, { useState, useEffect } from "react";

import { useAuth } from "../../contexts/AuthContext";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import SendIcon from "@mui/icons-material/Send";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ChangeHistoryOutlinedIcon from "@mui/icons-material/ChangeHistoryOutlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CropSquareOutlinedIcon from "@mui/icons-material/CropSquareOutlined";
import TextField from "@mui/material/TextField";
import { HuePicker, TwitterPicker, CompactPicker } from "react-color";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import PaletteOutlinedIcon from "@mui/icons-material/PaletteOutlined";
import { useSnackbar } from "notistack";

const MappingCreation = (props: any) => {
  const [shownPicker, setShownPicker] = React.useState<any>(-1);

  const handleToggle = (value: number) => () => {
    console.log("Value: " + JSON.stringify(value));
    props.setCurrentMapping([props.currentMapping, value]);
    const elementIndex = props.currentMapping.indexOf(value);

    if (elementIndex === -1) {
      // element not in list yet, we add it
      props.setCurrentMapping([...props.currentMapping, value]);
    } else {
      // remove element from list
      props.setCurrentMapping(
        props.currentMapping.filter((value, i) => i !== elementIndex)
      );
    }
  };

  return (
    <>
      <List
        sx={{ width: "100%", maxWidth: 1000, bgcolor: "background.paper" }}
        spacing={10}
      >
        {props.audienceList.map((audience: any, index: any) => {
          const labelId = `checkbox-list-label-${audience}`;

          return (
            <ListItem
              sx={{ margin: "20px 0;" }}
              key={index}
              secondaryAction={
                <Stack direction="row" spacing={4} alignItems="center">
                  {shownPicker != index && (
                    <IconButton
                      style={{
                        backgroundColor: props.colorStates[index],
                      }}
                      onClick={() => setShownPicker(index)}
                    >
                      <PaletteOutlinedIcon />
                    </IconButton>
                  )}

                  {shownPicker == index && (
                    <CompactPicker
                      triangle="hide"
                      color={props.colorStates[index]}
                      onChange={(color, event) => {
                        console.log("New color: " + color.hex);
                        let newState = [...props.colorStates];
                        newState[index] = color.hex;
                        props.setColorStates(newState);
                        setShownPicker(-1);
                      }}
                    />
                  )}

                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="demo-select-small"></InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      label=""
                      onChange={(event) => {
                        console.log("New value: " + event.target.value);
                        let newState = [...props.shapeStates];
                        newState[index] = event.target.value;
                        props.setShapeStates(newState);
                      }}
                    >
                      <MenuItem value="square" selected={true}>
                        <CropSquareOutlinedIcon />
                      </MenuItem>
                      <MenuItem value="circle">
                        <CircleOutlinedIcon />
                      </MenuItem>
                      <MenuItem value="diamond">
                      <CropSquareOutlinedIcon sx={{transform: 'rotate(45deg)'}} />

                      </MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              }
              disablePadding
            >
              <ListItemButton
                role={undefined}
                onClick={handleToggle(audience)}
                dense
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={props.currentMapping.indexOf(audience) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>

                <ListItemText
                  primary={audience.population_name}
                  secondary={audience.pop_type}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>

      <TextField id="standard-basic" label="Mapping name" variant="standard" onChange={(event) => props.setCurrentMappingName(event.target.value)}/>
    </>
  );
};

const MappingList = (props: any) => {
  return (
    <Paper elevation={3}>
      <List dense={true} role="list">
        {props.mappingList.map((mapping: any) => (
          <MappingItem
            deleteMapping={props.deleteMapping}
            mapping={mapping}
          />
        ))}
      </List>
    </Paper>
  );
};

const MappingItem = (props: any) => {
  //TODO: corriger texte
  var secondaryText = ""
  props.mapping.populations.map((population:any) =>  { secondaryText = secondaryText + ", " + population.population_name})
  return (
    <ListItem
      secondaryAction={
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => props.deleteMapping(props.mapping.uuid)}
        >
          <DeleteIcon />
        </IconButton>
      }
    >
      <ListItemText
        primary={"Mapping name: " + props.mapping.mapping_name}
        secondary={"Populations: " + secondaryText.substring(1)}
      />
    </ListItem>
  );
};

function Mappings(props: any) {
  const [currentMapping, setCurrentMapping] = React.useState([]);
  const [currentMappingName, setCurrentMappingName] = React.useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  var initShapeStates = [];
  var initColorStates = [];
  props.audienceList.map((audience: any, index: any) => {
    initShapeStates[index] = "square";
    initColorStates[index] = "#e61e1e";
  });
  const [shapeStates, setShapeStates] = React.useState<any>(initShapeStates);
  const [colorStates, setColorStates] = React.useState<any>(initColorStates);

  const addMapping = () => {

    if (currentMapping.length < 3){
      return enqueueSnackbar(`Mapping must contain at least 3 populations`);
    }

    var mappingCopy = [...currentMapping]
    mappingCopy.map((audience) => {
      const elementIndex = props.audienceList.indexOf(audience);
      audience.color = colorStates[elementIndex];
      audience.shape = shapeStates[elementIndex];
    })
    console.log("mappingCopy: " + JSON.stringify(mappingCopy))
    props.addMapping(currentMappingName, mappingCopy)

    // TODO: check mapping name

    // if (currentMapping.length > 1) {
    //   props.addMapping(currentMapping);
    //   setCurrentMapping([]);
    //   setCurrentMappingName("");
    // } else {
    //   console.log("Can't add new mapping: " + JSON.stringify(currentMapping));
    // }
  };

  return (
    <div>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={4}>
          <MappingCreation
            setCurrentMappingName={setCurrentMappingName}
            setCurrentMapping={setCurrentMapping}
            currentMapping={currentMapping}
            colorStates={colorStates}
            shapeStates={shapeStates}
            setColorStates={setColorStates}
            setShapeStates={setShapeStates}
            setCurrentMappingName={setCurrentMappingName}
            {...props}
          />
        </Grid>

        <Grid item xs={2}>
          <Button
            // type="submit"
            variant="contained"
            endIcon={<SendIcon />}
            onClick={addMapping}
          >
            Add
          </Button>
        </Grid>

        <Grid item xs={4}>
          <MappingList
            mappingList={props.mappingList}
            deleteMapping={props.deleteMapping}
            {...props}
          />
        </Grid>

        <Grid item xs={10}>
          <Button
            variant="contained"
            endIcon={<SendIcon />}
            fullWidth
            onClick={props.nextStep}
          >
            Next
          </Button>
          <br />
          <br />
        </Grid>

      </Grid>
    </div>
  );
}

export default Mappings;
