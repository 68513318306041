import { useState, useEffect } from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { useSnackbar } from "notistack";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from '@mui/icons-material/Delete';

const renderEmbeds = (params: any) => {
  return (
    <div>
      {params.row.embeds.authorizations.map((e: any) => (
        <Tooltip title={e.label}>
          <Chip label={e.token} />
        </Tooltip>
      ))}
    </div>
  );
};

const renderUrl = (params: any) => {
  const url = "/dashboards/viewer" + `${params.row.meta.slug}`;
  return <Link href={url} target="_blank" > {url} </Link>;
};



const RenderPassword = (params: any) => {
  interface State {
    amount: string;
    password: string;
    weight: string;
    weightRange: string;
    showPassword: boolean;
  }
  const [values, setValues] = useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  return (
    <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
      <Input
        id="standard-adornment-password"
        disableUnderline={true}
        type={values.showPassword ? "text" : "password"}
        value={`${params.row.accounts === undefined ? "-" : params.row.accounts[0].password }`} 
        onChange={handleChange("password")}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {values.showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

const DashboardDeleteButton = (props: any) => {
  const { currentUser } = useAuth();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sendRequestDeleteDashboard = (uuid: any, callback: any) => {
    console.log("UUID sent: " + uuid)
    currentUser.getIdToken(true).then(function (idToken: any) {
      fetch("/datastore/delete_dashboard", {
        method: "post",
        headers: { Authorization: idToken, "Content-Type": "application/json" },
        body: JSON.stringify({ uuid: uuid }),
      })
        .then((response) => response.json())
        .then((responseData) => {
          callback(responseData);
        });
    });
  };

  function afterCall(response: any) {
    enqueueSnackbar(response.message);
    props.setNeedReload(!props.needReload);
  }

  const handleConfirmation = () => {
    sendRequestDeleteDashboard(props.uuid, afterCall);
    setOpen(false);
  };

  return (
    <div>
      <IconButton
        onClick={handleClickOpen}

      >
          <DeleteIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete this dashboard ?"}
        </DialogTitle>
        
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {""}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirmation} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};



function DashboardHistory(props: any) {
  const { currentUser } = useAuth();
  const [requestList, setRequestList] = useState([]);
  const [historyLoading, setHistoryLoading] = useState(true);
  const [needReload, setNeedReload] = useState(true);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Request ID",
      editable: false,
      align: "center",
      headerAlign: "center",
      valueGetter: (params: GridValueGetterParams) => `${params.row.uuid}`,
      flex: 0.8,
    },
    {
      field: "name",
      headerName: "Dashboard name",
      editable: false,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params: GridValueGetterParams) => `${params.row.meta.name}`,
    },
    {
      field: "url",
      headerName: "URL",
      editable: false,
      flex: 1.6,
      align: "center",
      headerAlign: "center",
      renderCell: renderUrl,
    },
    {
      field: "username",
      headerName: "Username",
      editable: false,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.accounts === undefined ? "-" : params.row.accounts[0].user }`,
    },
    {
      field: "password",
      headerName: "Password",
      editable: false,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: RenderPassword,
    },
    {
      field: "embed",
      headerName: "Embeds",
      editable: false,
      flex: 1.8,
      align: "center",
      headerAlign: "center",
      renderCell: renderEmbeds,
    },
    {
      field: "creation_date",
      headerName: "Creation time",
      editable: false,
      flex: 0.7,
      align: "center",
      headerAlign: "center",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.metadata.creation_date}`,
    },
    {
      field: "email",
      headerName: "Owner",
      editable: false,
      flex: 0.8,
      align: "center",
      headerAlign: "center",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.metadata.user.email}`,
    },
    {
      field: "delete_dashboard",
      headerName: "Delete",
      editable: false,
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <DashboardDeleteButton
            uuid={params.row.uuid}
            params={params.row}
            needReload={needReload}
            setNeedReload={setNeedReload}
          />
        );
      },
    },
  ];



  useEffect(() => {
    const initialize = () => {
      // get access token to auth to backend then fetch (with token in header?)
      currentUser.getIdToken(true).then(function (idToken: any) {
        fetch("/datastore/get/requests/all/dashboard", {
          method: "post",
          headers: {
            Authorization: idToken,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((responseData) => {
            setRequestList(responseData.requests);
            setHistoryLoading(false);
          });
      });
    };
    return initialize();
  }, [needReload]);

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={10}>
        {!historyLoading && (
          <div style={{ height: 800, width: "100%" }}>
            <DataGrid
              rows={requestList}
              columns={columns}
              pageSize={30}
              disableSelectionOnClick
              getRowId={(row) => row.uuid}

              initialState={{
                sorting: {
                  sortModel: [{ field: 'creation_date', sort: 'desc' }],
                }
              }}
            />
          </div>
        )}

        {historyLoading && (
          <Grid item xs={10} alignContent="center">
            <CircularProgress />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default DashboardHistory;
