import React, { useState, useEffect } from "react";

import { useFormik } from "formik";

import * as yup from "yup";

import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import Divider from "@mui/material/Divider";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTheme } from "@mui/styles";
import { makeStyles } from "@mui/styles";

import { format} from 'date-fns'

import { useAuth } from "../../contexts/AuthContext";

import {
  startValueValidator,
  endValueValidator,
  delayValidator,
} from "../validators";

export const SelectTaxos = (props: any) => {
  const [taxosList, setTaxosList] = useState([]);
  const { currentUser } = useAuth();

  useEffect(() => {
    const initialize = () => {
      currentUser.getIdToken(true).then(function (idToken: any) {
        fetch("/field/taxos/" + props.country, {
          method: "get",
          headers: { Authorization: idToken },
        })
          .then((response) => response.json())
          .then((responseData) => {
            setTaxosList(responseData.taxos.sort((a: any, b: any) => {
              const labelA = getLabel(a).toLowerCase();
              const labelB = getLabel(b).toLowerCase();
              if (labelA < labelB) {
                return -1;
              }
              if (labelA > labelB) {
                return 1;
              }
              return 0;
            }));
          });
      });
    };
    return initialize();
  }, []);

  const getLabel = (option: any) => {
    if(option.name){
      return option.id + " - " + option.name;
    }else{
      return option.id
    }
    
  };

  return (
    <Autocomplete
      multiple={props.multiple}
      id="taxos"
      options={taxosList}
      getOptionLabel={getLabel}
      filterSelectedOptions
      onChange={props.onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Taxos"
          placeholder="Click to show taxos list"
        />
      )}
    />
  );
};

const useStyles = makeStyles({
  root: {
    color: ""
  }
});

export default function DatafetcherFeatures(props: any) {

  const theme = useTheme();

  const classes = useStyles();

  const featureValidationSchema = yup.object({
    date_start: startValueValidator,
    date_end: endValueValidator,
    delay_before: delayValidator,
    delay_after: delayValidator,
  });

  // TODO: check if still useful after renaming
  function formatFeatures(allFeatures: any) {
    const globalParameters = {
      project: allFeatures.project,
      date_start: format(allFeatures.date_start, "yyyyMMdd"),
      date_end: format(allFeatures.date_end, "yyyyMMdd"),
      use_delay: allFeatures.use_delay,
      delay_before: parseInt(allFeatures.delay_before),
      delay_after: parseInt(allFeatures.delay_after),
      first_last_next_all: allFeatures.first_last_next_all,
    };

    const bigseaParameters = {
      country: allFeatures.country,
      only_in_bigsea: allFeatures.only_in_bigsea,
      used: true,
    };

    const bigfishParameters = {
      taxo_eu_or_us: allFeatures.taxo_eu_or_us,
      bigfish_id: allFeatures.taxosList.map((t:any) => t.name),
      only_in_bigfish: allFeatures.only_in_bigfish,
      used: true,
    };
    return {
      global_parameters: globalParameters,
      bigsea_parameters: bigseaParameters,
      bigfish_parameters: bigfishParameters,
    };
  }

  const formik = useFormik({
    initialValues: {
      project: "datamining-1184",
      date_start: null,
      date_end: null,
      use_delay: true,
      delay_before: 0,
      delay_after: 0,
      first_last_next_all: "first",
      country: "",
      only_in_bigsea: true,
      taxo_eu_or_us: "EU",
      only_in_bigfish: true,
      taxosList: [],
    },
    validationSchema: featureValidationSchema,
    onSubmit: (value) => {
      const formattedFeatures = formatFeatures(value);
      props.setFeatures(formattedFeatures);
      props.nextStep();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={4} alignItems="center" justifyContent="center">
        <Grid item xs={10}>
          <Divider className={classes.root}>Global parameters</Divider>
        </Grid>

        <Grid item xs={10}>
          <TextField
            id="project"
            name="project"
            label="Project"
            fullWidth
            value={formik.values.project}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.project)}
            helperText={formik.touched.project && formik.errors.project}
          />
        </Grid>

        <Grid item xs={10}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Date start"
              inputFormat="dd/MM/yyyy"
              value={formik.values.date_start}
              onChange={(value: any): void => {
                formik.setFieldValue("date_start", value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  helperText={
                    formik.touched.date_start && formik.errors.date_start
                  }
                  error={
                    formik.touched.date_start &&
                    Boolean(formik.errors.date_start)
                  }
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={10}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Date end"
              inputFormat="dd/MM/yyyy"
              value={formik.values.date_end}
              onChange={(value: any): void => {
                formik.setFieldValue("date_end", value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  helperText={formik.touched.date_end && formik.errors.date_end}
                  error={
                    formik.touched.date_end && Boolean(formik.errors.date_end)
                  }
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={10}>
          <FormGroup>
            <FormControlLabel
              control={<Switch defaultChecked />}
              label="Use delay"
              value={formik.values.use_delay}
              onChange={formik.handleChange("use_delay")}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={10}>
          <TextField
            id="delay_before"
            name="delay_before"
            label="Delay before"
            value={formik.values.delay_before}
            onChange={formik.handleChange("delay_before")}
            error={
              formik.touched.delay_before && Boolean(formik.errors.delay_before)
            }
            helperText={formik.touched.delay_before && formik.errors.delay_before}
            fullWidth
          />
        </Grid>
        <Grid item xs={10}>
          <TextField
            id="delay_after"
            name="delay_after"
            label="Delay after"
            value={formik.values.delay_after}
            onChange={formik.handleChange("delay_after")}
            error={
              formik.touched.delay_after && Boolean(formik.errors.delay_after)
            }
            helperText={formik.touched.delay_after && formik.errors.delay_after}
            fullWidth
          />
        </Grid>

        <Grid item xs={10}>
          <TextField
            id="first_last_next_all"
            select
            label="Profile Date"
            value={formik.values.first_last_next_all}
            onChange={formik.handleChange("first_last_next_all")}
            helperText="Select a profile date"
            fullWidth
          >
            <MenuItem value={"first"}>First</MenuItem>
            <MenuItem value={"last"}>Last</MenuItem>
            <MenuItem value={"next"}>Next</MenuItem>
            <MenuItem value={"all"}>All</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={10}>
          <Divider>General taxonomy parameters</Divider>
        </Grid>

        <Grid item xs={10}>
          <TextField
            id="country"
            select
            label="Country"
            value={formik.values.country}
            onChange={formik.handleChange("country")}
            helperText="Select a country"
            fullWidth
          >
            <MenuItem value={"FR"}>France</MenuItem>
            <MenuItem value={"ES"}>Spain</MenuItem>
            <MenuItem value={"PT"}>Portugal</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={10}>
          <FormGroup>
            <FormControlLabel
              control={<Switch defaultChecked />}
              label="Only in Bigsea"
              onChange={formik.handleChange("only_in_bigsea")}
              value={formik.values.only_in_bigsea}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={10}>
          <Divider>Custom taxonomies parameters</Divider>
        </Grid>

        <Grid item xs={10}>
          <TextField
            id="taxo_eu_or_us"
            select
            label="Taxo EU or US"
            value={formik.values.taxo_eu_or_us}
            onChange={formik.handleChange("taxo_eu_or_us")}
            helperText="EU or US taxos"
            fullWidth
          >
            <MenuItem value={"EU"}>EU</MenuItem>
            <MenuItem value={"US"}>US</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={10}>
          <FormGroup>
            <FormControlLabel
              control={<Switch defaultChecked />}
              label="Only in Bigfish"
              value={formik.values.only_in_bigfish}
              onChange={formik.handleChange("only_in_bigfish")}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={10}>
          <SelectTaxos
            onChange={(event: any, value: any): void => {
              formik.setFieldValue("taxosList", value);
            }}
            multiple={true}
          />
        </Grid>

        <Grid item xs={10}>
          <Button
            type="submit"
            variant="contained"
            endIcon={<SendIcon />}
            fullWidth
          >
            Next
          </Button>
          <br />
          <br />
          <Button variant="contained" fullWidth onClick={props.prevStep}>
            Back
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
