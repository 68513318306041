import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import * as yup from "yup";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import { EmbedInput } from "./EmbedInput";
import EmbedList from "./EmbedList";
import { useSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";
import usePostRequest from "../utils/requests"
import {letterNumberValidator, letterNumberSpaceValidator, letterNumberUnderscoreDashValidator} from "../validators"
import Tooltip from '@mui/material/Tooltip';
import { CSSProperties } from 'react';

const dashCreationValidationSchema = yup.object({
  user: letterNumberValidator("User", 3),
  password: letterNumberValidator("Password", 10),
  name: letterNumberSpaceValidator("Dashboard name", 3),
  slug: letterNumberUnderscoreDashValidator("Url suffix", 5)
});

function DashCreation(props: any) {
  const [embedList, setEmbedList] = useState<any>([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const runPostRequest = usePostRequest()

  const deleteEmbed = (uuid: any) => {
    setEmbedList(embedList.filter((embed: any) => embed.uuid !== uuid));
    enqueueSnackbar(`Embed deleted`);
  };

  const addEmbed = (newEmbed: any) => {
    if (newEmbed !== null) {
      if (embedList.filter((e: any) => e.label === newEmbed.label).length > 0) {
        return;
      }
      newEmbed.uuid = uuidv4();
      setEmbedList([...embedList, newEmbed]);
      enqueueSnackbar(`Embed '${newEmbed.label}' added`);
    }
  };
  
  const centeredMessageStyle: CSSProperties = { textAlign: 'center',
  color: 'red',
  border: '2px solid red',
  padding: '10px' };


  const formik = useFormik({
    initialValues: {
      user: "",
      password: "",
      name: "",
      slug: ""
    },
    validationSchema: dashCreationValidationSchema,
    onSubmit: async (value: any) => {
      const accounts = [{"user":value.user, "password":value.password}]
      const meta = {"slug": "/" + value.slug, "name":value.name};
      const embeds = embedList.map( (e:any) =>  ({"token": e.token, "label":e.label, "permissions":["READ","FILTER","EXPORT"]}) )
      var dash_entity = {"accounts":accounts, "meta":meta, "embeds":{"authorizations": embeds}}
      await runPostRequest(dash_entity, "/datastore/create_dashboard", "/home", true, null)
    }
  });

  return (
    <>
       <form id="dashcreation" onSubmit={formik.handleSubmit}>
        <Grid container spacing={4} alignItems="center" justifyContent="center">
          <Grid item xs={10}>
          
            <TextField
              id="name"
              name="name"
              label="Dashboard name"
              fullWidth
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            
          </Grid>

          <Grid item xs={10}>
          <Tooltip title="URL suffix will be used to generate the url where the dashboard will be accessible" arrow>
            <TextField
              id="slug"
              name="slug"
              label="URL Suffix"
              fullWidth
              value={formik.values.slug}
              onChange={formik.handleChange}
              error={formik.touched.slug && Boolean(formik.errors.slug)}
              helperText={formik.touched.slug && formik.errors.slug}
            />
            </Tooltip>
          </Grid>

          <Grid item xs={10}>
          <Tooltip title="Username to log into the dashboard" arrow>
            <TextField
              id="user"
              name="user"
              label="Username"
              fullWidth
              value={formik.values.user}
              onChange={formik.handleChange}
              error={formik.touched.user && Boolean(formik.errors.user)}
              helperText={formik.touched.user && formik.errors.user}
            />
            </Tooltip>
          </Grid>

          <Grid item xs={10}>
          <Tooltip title="Password to log into the dashboard" arrow>
            <TextField
              id="password"
              name="password"
              label="Password"
              type="password"
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.user && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            </Tooltip>
          </Grid>
          

        </Grid>
      </form>
      <br/><br/>

      <Grid container spacing={4} alignItems="center" justifyContent="center">
        <Grid item xs={5}>
          <EmbedInput addEmbed={addEmbed} />
        </Grid>

        <Grid item xs={5}>
          <EmbedList embedList={embedList} deleteEmbed={deleteEmbed} />
        </Grid>

        <Grid item xs={10}>
            <Button
              type="submit"
              variant="contained"
              endIcon={<SendIcon />}
              fullWidth
              form="dashcreation"
            >
              Create dashboard
            </Button>
            <br />
            <br />
          </Grid>
      </Grid> 

    </>
  );
}

export default DashCreation;
